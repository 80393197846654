<script lang="ts">
  import { EnumT } from "@shared/schema/index.ts"
  import type { AcceptCallback } from "../modalManager/types.ts"
  import ModalFooter from "./ModalFooter.svelte"
  import SingleSelect from "../SingleSelect.svelte"
  import type { OptionT } from "@/types/index.ts"
  import CopyText from "../controls/CopyText.svelte"
  import SpacingWrapper from "../layout/SpacingWrapper.svelte"
  import type { ShareableData } from "../share/types.ts"

  export let accessType: EnumT.AccessType
  export let shareData: ShareableData
  export let editDisabled: boolean
  export let onAccept: AcceptCallback<EnumT.AccessType> | undefined = undefined

  const ACCESS_TYPE_OPTIONS: OptionT<EnumT.AccessType>[] = [
    { label: "View", value: EnumT.AccessType.viewer },
    { label: "Edit", value: EnumT.AccessType.owner },
  ]

  const doAccept = () => {
    if (editDisabled) {
      return
    }
    onAccept?.(accessType)
  }
</script>

<div class="content">
  <SingleSelect
    label="Users with link can:"
    disabled={editDisabled}
    options={ACCESS_TYPE_OPTIONS}
    bind:value={accessType}
  />
  <SpacingWrapper axis="y">
    Share link:
    <CopyText value={shareData.url ?? ""} {shareData} />
  </SpacingWrapper>
</div>
<ModalFooter on:close acceptDisabled={editDisabled} onAccept={doAccept} />
