<script lang="ts">
  import SourceCard from "@/lib/SourceCard.svelte"
  import { listingTypeInfoMap, sourceInfoMap } from "@shared/data/defaults"
  import type { EnumT } from "@shared/schema"
  import { objectEntries } from "@shared/util"

  export let listingType: EnumT.ListingType | null

  $: emoji = listingType ? listingTypeInfoMap[listingType].emoji : "💭"
  $: name = listingType ? listingTypeInfoMap[listingType].name.plural : "Other"
  $: sources = listingType
    ? objectEntries(sourceInfoMap)
        .filter(
          ([_source, info]) =>
            listingType && info.listingTypes?.includes(listingType)
        )
        .map(([source]) => source)
    : []
</script>

<div class="listing-type-card">
  <div class="title-row">
    <div class="emoji">{emoji}</div>
    <div class="name">{name}</div>
  </div>
  <div class="sources">
    {#each sources as source}
      <div class="source">
        <SourceCard nofocus readonly size="small" {source} />
      </div>
    {/each}
  </div>
</div>

<style>
  .listing-type-card {
    background-color: var(--secondary-bg);
    padding: 0.7em;
  }
  .source {
    margin: 0.3em;
    display: inline-block;
  }
  .sources {
    margin-top: 0.4em;
  }
  .emoji {
    font-size: 2em;
  }
  .name {
    font-size: 1.5em;
    margin-left: 0.4em;
  }
  .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
