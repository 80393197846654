<script lang="ts" context="module">
  export type Position = "left" | "right" | "top" | "bottom"
</script>

<script lang="ts">
  import { classNames } from "@/util"
  import { assertDefined } from "@shared/util"
  import { fly } from "svelte/transition"

  export let active: boolean = false
  export let position: Position | undefined = undefined
  let dummyElem: HTMLSpanElement | undefined = undefined
  let wrapperElem: HTMLDivElement | undefined = undefined

  $: resolvedPosition = ((): Position => {
    if (!dummyElem || position) {
      return position ?? "right"
    }
    const parentElem = assertDefined(dummyElem.parentElement)
    const viewportWidth = window.innerWidth
    const parentBoundingBox = parentElem.getBoundingClientRect()
    const leftDistance = parentBoundingBox.left
    const rightDistance = viewportWidth - parentBoundingBox.right
    if (rightDistance > leftDistance) {
      return "right"
    } else {
      return "left"
    }
  })()

  $: flyTrans = {
    duration: 200,
    x:
      resolvedPosition === "right" ? -10 : resolvedPosition === "left" ? 10 : 0,
  }

  const onDocumentClick = (e: MouseEvent) => {
    if (
      active &&
      e.target instanceof Element &&
      !wrapperElem?.contains(e.target)
    ) {
      active = false
    }
  }

  const addEventListener = () =>
    document.addEventListener("mouseup", onDocumentClick)

  const removeEventListener = () =>
    document.removeEventListener("mouseup", onDocumentClick)

  $: if (active) {
    addEventListener()
  } else {
    removeEventListener()
  }
</script>

<!-- prettier-ignore -->
<span bind:this={dummyElem} class="dummy" />{#if active}
  <div
    class={classNames("popover-wrapper", resolvedPosition)}
    transition:fly={flyTrans}
    bind:this={wrapperElem}
    on:click|stopPropagation
  >
    <div class={classNames("popover-arrow", resolvedPosition)} />
    <div class={classNames("popover-content-wrapper", resolvedPosition)}>
      <slot />
    </div>
  </div>
{/if}

<style>
  .popover-wrapper {
    position: absolute;
    display: flex;
  }
  .popover-content-wrapper {
    background-color: var(--secondary-bg);
    border: 2px solid var(--action-alt);
    border-radius: 2px;
    padding: 8px;
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
  }
  .popover-wrapper.right {
    right: 0;
    top: 50%;
    transform: translateX(100%) translateY(-50%);
    flex-direction: row;
  }
  .popover-wrapper.left {
    left: 0;
    top: 50%;
    transform: translateX(-100%) translateY(-50%);
    flex-direction: row;
  }
  .popover-arrow {
    background-color: var(--action-alt);
    width: 20px;
    aspect-ratio: 1;
    clip-path: polygon(75% 93.3%, 0% 50%, 75% 6.7%);
    align-self: center;
  }
  .popover-arrow.right {
    order: 0;
    margin-right: -6px;
  }
  .popover-arrow.left {
    order: 2;
    transform: rotate(180deg);
    margin-left: -6px;
  }
</style>
