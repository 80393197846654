import type { ComponentProps } from "svelte"
import { globalModalManager } from "."
import ConfirmModal from "./ConfirmModal.svelte"

export const confirmDelete = ({
  title,
  description,
}: {
  title: string
  description: string
}) =>
  new Promise<boolean>((resolve) => {
    const confirmModal = globalModalManager.makeModal<
      boolean,
      ComponentProps<ConfirmModal>
    >({
      title,
      description,
      component: ConfirmModal,
    })
    confirmModal.open({
      props: { acceptVariant: "danger", acceptText: "Delete" },
      onAccept: (confirmed: boolean) => resolve(confirmed),
    })
  })
