<script context="module" lang="ts">
  export type InputDataType = "text" | "number"
</script>

<script lang="ts">
  import type { OptionT, Size, Variant } from "@/types"
  import { classNames } from "@/util"

  import { safeParseFloat } from "@shared/util/index.ts"
  import { createEventDispatcher } from "svelte"
  import SuggestionsWrapper from "./SuggestionsWrapper.svelte"

  type OT = $$Generic
  export let type: InputDataType
  type DT = $$Generic<typeof type extends "text" ? string : number | "">
  type T = DT | null
  export let value: T | undefined
  export let options: OptionT<OT>[] = []
  export let variant: Variant = "default"
  export let variants: Variant[] = []
  export let id: string | undefined = undefined
  export let autofocus: boolean = false
  export let elem: HTMLInputElement | undefined = undefined
  export let fullwidth: boolean = false
  export let fullheight: boolean = false
  export let placeholder: string | undefined = undefined
  export let disabled: boolean = false
  export let readonly: boolean = false
  export let plaincursor: boolean = false
  export let suggestions: string[] = []
  export let inline: boolean = false
  export let onChange: ((updatedValue: T) => void) | undefined = undefined
  export let size: Size = "default"

  const dispatch = createEventDispatcher<{
    selectOption: OT
  }>()

  let closeWrapper = false

  $: computedVariantClasses = [...variants, variant].map((v) => `variant-${v}`)
  $: computedSizeClasses = `size-${size}`

  const numericKeys = new Set([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "-",
  ])

  const checkNumeric = (ev: KeyboardEvent) => {
    if (
      (ev.key === "-" && value?.toString().length !== 1) ||
      (ev.key === "." && value?.toString().includes(".")) ||
      !numericKeys.has(ev.key)
    ) {
      ev.preventDefault()
    }
  }

  const stringValueInput = (ev: Event) => {
    const inputValue = (ev.target as HTMLInputElement).value as T
    if (onChange) {
      onChange(inputValue)
    } else {
      value = inputValue
    }
  }

  const numericValueInput = (ev: Event) => {
    const inputValue = safeParseFloat(
      (ev.target as HTMLInputElement).value
    ) as T | null
    if (inputValue === null) {
      ev.preventDefault()
      value = undefined
    } else {
      if (onChange) {
        onChange(inputValue)
      } else {
        value = inputValue
      }
    }
  }

  const selectOption = (ev: CustomEvent<OT>) => {
    closeWrapper = true
    dispatch("selectOption", ev.detail)
  }

  // TODO: Incomplete
  const checkNumericPaste = (ev: ClipboardEvent) => {
    ev.preventDefault()
    const val = safeParseFloat(ev.clipboardData?.getData("text") ?? "") ?? ""
  }

  $: if (options) {
    closeWrapper = false
  }

  $: expanded = options.length > 0 && !closeWrapper
</script>

<SuggestionsWrapper
  {expanded}
  {fullwidth}
  {fullheight}
  {options}
  {inline}
  on:selectOption={selectOption}
>
  <div
    class:inline
    class:expanded
    class={classNames(computedVariantClasses, "wrapper")}
  >
    {#if type === "text"}
      <input
        bind:this={elem}
        {id}
        {autofocus}
        {placeholder}
        {disabled}
        {readonly}
        value={value ?? ""}
        on:blur
        on:keydown
        on:click
        on:mouseup
        class={classNames(computedVariantClasses, computedSizeClasses, {
          expanded,
          inline,
        })}
        class:plaincursor
        on:input={stringValueInput}
        type="text"
      />
    {:else if type === "number"}
      <input
        bind:this={elem}
        value={value ?? ""}
        {id}
        {autofocus}
        {placeholder}
        {disabled}
        {readonly}
        class={classNames(computedVariantClasses, computedSizeClasses, {
          expanded,
          inline,
        })}
        class:fullwidth
        on:click
        on:mouseup
        on:input={numericValueInput}
        on:keypress={checkNumeric}
        on:paste={checkNumericPaste}
        on:blur
        on:keydown
        class:plaincursor
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
      />
    {/if}
    {#if $$slots.right}
      <div class="right">
        <slot name="right" />
      </div>
    {/if}
    {#if $$slots.left}
      <div class="left">
        <slot name="left" />
      </div>
    {/if}
  </div>
</SuggestionsWrapper>

<style>
  .wrapper {
    position: relative;
    display: flex;
  }
  .wrapper:not(.variant-naked) {
    font-size: 15px;
    flex: 1;
  }
  .wrapper.variant-default {
    background-color: var(--secondary-bg);
    color: var(--secondary-fg);
    border: 2px solid var(--secondary-accent);
    border-radius: 8px;
  }
  .wrapper.variant-default:focus-within {
    outline: none;
    border: 2px solid var(--action-alt);
  }
  .plaincursor {
    cursor: default;
  }
  .wrapper.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-color: var(--action-alt);
    border-left-color: var(--action-alt);
    border-right-color: var(--action-alt);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  .right {
    display: inline-block;
  }
  .wrapper.inline {
    display: inline-block;
  }
  input:not(.variant-naked) {
    flex: 1;
    /*padding-left: 4px;*/
    padding: 4px;
    border: none;
    outline: none;
  }
  input {
    background: none;
    outline: none;
  }
  input.size-large {
    font-size: 120%;
  }
</style>
