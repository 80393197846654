import "./app.css"
import App from "./App.svelte"

import * as Sentry from "@sentry/svelte"
import { safeParseFloat } from "@shared/util/index.ts"
import { initMobileApp, isMobileApp } from "./mobile/index.ts"

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN as string | undefined,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.zodErrorsIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  profilesSampleRate:
    safeParseFloat(
      (import.meta.env.VITE_APP_SENTRY_PROFILES_SAMPLE_RATE as
        | string
        | undefined) ?? ""
    ) ?? 1.0,
  tracesSampleRate:
    safeParseFloat(
      (import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE as
        | string
        | undefined) ?? ""
    ) ?? 1.0,
  replaysSessionSampleRate:
    safeParseFloat(
      (import.meta.env.VITE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE as
        | string
        | undefined) ?? ""
    ) ?? 0.1,
  replaysOnErrorSampleRate:
    safeParseFloat(
      (import.meta.env.VITE_APP_SENTRY_REPLAYS_ERROR_SAMPLE_RATE as
        | string
        | undefined) ?? ""
    ) ?? 1.0,
  release: import.meta.env.VITE_APP_VERSION as string | undefined,
})

const mountPoint = document.getElementById("app")

if (!mountPoint) {
  throw "mount point not found, app cannot be rendered"
}

initMobileApp()

const app = new App({
  target: mountPoint,
  props: { isMobileApp: isMobileApp() },
})

export default app
