<script lang="ts">
  import Button from "@/lib/Button.svelte"
  import {
    activeSheetId,
    hasWriteAccess,
    sheetContentFactory,
  } from "@/stores/sheetStore"
  import {
    faEye,
    faPlusCircle,
    faRetweet,
    faTableColumns,
  } from "@fortawesome/free-solid-svg-icons"
  import { toggleTranspose, transpose } from "@/stores/transpose"
  import type { OpenAddVariableModal } from "@/lib/generatorSpec/types"
  import type { AddColumnInfo } from "@/lib/sidepanels/AddColumnSidepanel.svelte"
  import type { DataViewModule } from "./module.ts"
  import SpacingWrapper from "@/lib/layout/SpacingWrapper.svelte"
  import FixedLayoutHorizontal from "@/lib/layout/FixedLayoutHorizontal.svelte"
  import Icon from "@/lib/Icon.svelte"
  import { getSocket } from "@/client/socket.ts"

  $: sheetContent = $activeSheetId ? $sheetContentFactory($activeSheetId) : null
  $: hasWrite = hasWriteAccess({ sheetId: $activeSheetId ?? undefined })

  export let context: DataViewModule

  const openAddVariableModal: OpenAddVariableModal = (modalArgs, onAccept) =>
    context.addVariableModal.open({ props: modalArgs, onAccept })

  const openAddColumnSidepanel = () => {
    if (sheetContent) {
      context.addColumnSidepanel.open({
        props: {
          sheetId: sheetContent.sheet.id,
          openAddVariableModal,
          accept: async (info: AddColumnInfo) => {
            context.addColumnSidepanel.close()
            if ($activeSheetId) {
              ;(await getSocket()).emit("createColumn", {
                ...info,
                sheetId: $activeSheetId,
              })
            }
          },
        },
      })
    }
  }
  const openSetColumnsSidepanel = () => {
    if (sheetContent) {
      context.setColumnsSidepanel.open({
        props: { sheetId: sheetContent.sheet.id },
      })
    }
  }
</script>

<FixedLayoutHorizontal flexRight flexLeft>
  <svelte:fragment slot="left">
    {#if $hasWrite !== undefined && !$hasWrite}
      <span class="warning-wrapper">
        <SpacingWrapper
          ><Icon icon={faEye} /><span class="view-only">&nbsp;View only</span
          ></SpacingWrapper
        >
      </span>
    {/if}
  </svelte:fragment>
  <svelte:fragment slot="right">
    {#if $activeSheetId && (sheetContent?.rows.length ?? 0) > 0}
      <SpacingWrapper sides={["top", "bottom"]}>
        <Button iconLeft={faRetweet} on:click={toggleTranspose}
          >{$transpose ? "Table View" : "Compare View"}</Button
        >
      </SpacingWrapper>
      <SpacingWrapper sides={["left", "top", "bottom"]}>
        <Button
          disabled={!$hasWrite}
          iconLeft={faPlusCircle}
          on:click={openAddColumnSidepanel}>New Field</Button
        >
      </SpacingWrapper>
      <SpacingWrapper sides={["left", "top", "bottom", "right"]}>
        <Button
          disabled={!$hasWrite}
          iconLeft={faTableColumns}
          on:click={openSetColumnsSidepanel}>Show/Hide Columns</Button
        >
      </SpacingWrapper>
    {/if}
  </svelte:fragment>
</FixedLayoutHorizontal>

<style>
  .warning-wrapper {
    align-self: center;
    color: var(--success-fg);
    font-weight: 700;
  }
  .view-only {
    user-select: none;
  }
  @media only screen and (max-width: 769px) {
    .view-only {
      display: none;
    }
  }
</style>
