<script lang="ts">
  import Button from "@/lib/Button.svelte"

  import Icon from "@/lib/Icon.svelte"
  import type { Variant } from "@/types"
  import { classNames } from "@/util"
  import { COMMON_ANIMATION_OPTIONS } from "@/util/animation"

  import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
  import { animate, inView } from "motion"
  import { onDestroy, onMount } from "svelte"

  export let title: string
  export let emoji: string | undefined = undefined
  export let icon: IconDefinition | undefined = undefined
  export let actionText: string
  export let variant: Variant = "default"
  export let actionIconLeft: IconDefinition | undefined = undefined
  export let actionIconRight: IconDefinition | undefined = undefined
  export let to: string | undefined = undefined

  let stopCB: (() => void) | undefined = undefined
  let panelElem: HTMLDivElement

  onMount(() => {
    stopCB = inView(panelElem, () => {
      animate(
        panelElem,
        { opacity: [0, 1], y: [100, 0] },
        { duration: 0.8, ...COMMON_ANIMATION_OPTIONS }
      )
    })
  })

  onDestroy(() => stopCB?.())
</script>

<div class="panel" bind:this={panelElem}>
  <div class="title-row">
    <div class={classNames("icon", `variant-${variant}`)}>
      {#if emoji}
        {emoji}
      {:else if icon}
        <Icon {icon} />
      {/if}
    </div>
    <div class="title">{title}</div>
  </div>
  <p><slot /></p>
  <div class="footer">
    <Button
      {to}
      on:click
      iconRight={actionIconRight}
      iconLeft={actionIconLeft}
      {variant}>{actionText}</Button
    >
  </div>
</div>

<style>
  .panel {
    background-color: var(--primary-bg);
    color: var(--primary-fg);
    font-size: 20px;
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
    border-radius: var(--default-rounding);
    padding: 15px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(100px);
  }
  .icon {
    margin-right: 10px;
    background-color: var(--secondary-bg);
    border-radius: var(--default-rounding);
    padding: 5px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    aspect-ratio: 1 / 1;
  }
  .icon.variant-success {
    color: var(--success-fg);
    background-color: var(--success-bg);
  }
  .icon.variant-action {
    color: var(--action-fg);
    background-color: var(--action-bg);
  }
  .icon.variant-action-alt {
    color: var(--action-alt-fg);
    background-color: var(--action-alt-bg);
  }
  .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .title {
    flex: 1;
    font-size: 30px;
    font-family: "Sarabun", sans-serif;
  }
  p {
    flex: 1;
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
</style>
