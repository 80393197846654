<script lang="ts">
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import type { DataFormatOverrides } from "./types"
  import type { EnumT } from "@shared/schema"
  import DisplayTextArray from "./DisplayTextArray.svelte"

  export let value: GenericDataValue<EnumT.DataType.textArray> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
</script>

<!-- TODO: editing of generic text arrays is unsupported, fall back to the display component -->
<DisplayTextArray {value} {formatSpec} {dataFormatOverrides} />
