import { publicUrl, sheetRedirectRoute, type SheetRouteBase } from "@/routes"
import type { ShareableData } from "./types.ts"

export const Shareable = {
  sheetLink: (sheetRouteProps: SheetRouteBase): ShareableData => ({
    title: "Check out my list on Listable!",
    dialogTitle: "Share your list",
    url: publicUrl(sheetRedirectRoute(sheetRouteProps)).toString(),
  }),
}
