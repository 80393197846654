import { derived, writable } from "svelte/store"
import ToastManager from "./ToastManager.svelte"
import type { ToastMessageT, ToastMessageExtended, Position } from "./types.ts"

export { ToastManager }

const DEFAULT_DURATION = 6000

export const createToastManager = (position: Position) => {
  const toastsInner = writable<ToastMessageExtended[]>([])
  const toasts = derived(toastsInner, ($toastsInner) => $toastsInner)

  const removeToast = (id: string) => {
    toastsInner.update(($toasts) => $toasts.filter((tm) => tm.id !== id))
  }

  const pushToast = (tm: ToastMessageT) => {
    const id = crypto.randomUUID()
    toastsInner.update(($toasts) => [...$toasts, { ...tm, id }])
    setTimeout(() => {
      removeToast(id)
    }, tm.duration ?? DEFAULT_DURATION)
    return id
  }

  return {
    toasts,
    position,
    pushToast,
    removeToast,
  }
}

export const bottomRightToastManager = createToastManager("bottom-right")
export const topCenterToastManager = createToastManager("top-center")
