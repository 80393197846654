<script lang="ts">
  export let flexBody: boolean = false
</script>

<div class="page">
  <div>
    <slot name="header" />
  </div>
  <div class="body" class:flex-body={flexBody}>
    <slot />
  </div>
  <div>
    <slot name="footer" />
  </div>
</div>

<style>
  .page {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .body {
    flex: 1;
    overflow-y: auto;
    height: 100%;
  }
  .flex-body {
    display: flex;
    flex-direction: column;
  }
</style>
