<script lang="ts">
  import {
    faCheckCircle,
    faCircleExclamation,
    faCopy,
  } from "@fortawesome/free-solid-svg-icons"
  import Button from "../Button.svelte"
  import Input from "../Input.svelte"
  import { bottomRightToastManager } from "../ToastManager"
  import type { ShareableData } from "../share/types"
  import ShareButton from "../share/ShareButton.svelte"
  import { isMobile } from "@/util"

  export let value: string
  export let shareData: ShareableData | undefined = undefined

  const copyValue = async () => {
    try {
      await navigator.clipboard.writeText(value)
      bottomRightToastManager.pushToast({
        message: "Successfully copied to clipboard",
        variant: "success",
        icon: faCheckCircle,
      })
    } catch {
      bottomRightToastManager.pushToast({
        message: "Failed to copy, try selecting text and copying manually",
        variant: "danger",
        icon: faCircleExclamation,
      })
    }
  }
</script>

<div class="content-wrapper">
  <Input fullwidth {value} readonly type="text" plaincursor /><Button
    iconLeft={faCopy}
    on:click={copyValue}
  />{#if shareData && isMobile()}<ShareButton
      disableFallbackModal
      {shareData}
    />{/if}
</div>

<style>
  .content-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
</style>
