import { App } from "@capacitor/app"
import { Capacitor } from "@capacitor/core"

let appIsActive = false

export const isMobileApp = () => Capacitor.isNativePlatform()

export const initMobileApp = () => {
  if (!isMobileApp()) {
    return false
  }

  void App.addListener("appStateChange", (appState) => {
    appIsActive = appState.isActive
  })

  void App.addListener("appUrlOpen", (data) => {
    console.log("App opened with URL:", data)
  })

  void App.addListener("appRestoredResult", (data) => {
    console.log("Restored state:", data)
  })

  void App.addListener("backButton", (data) => {
    console.log("back button pressed", data)
    window.history.back()
  })

  return true
}

export const checkAppLaunchUrl = async () => {
  const url = (await App.getLaunchUrl())?.url

  return { url }
}

export const isActive = () => appIsActive
