import { EnumT } from "../schema"
import { assertDefined, memoize, newTypedArray } from "../util"

const ACCESS_TYPE_ORDER: EnumT.AccessType[] = [
  EnumT.AccessType.owner,
  EnumT.AccessType.viewer,
]

export const isAccessTypeGranted = ({
  actualAccessType,
  requestedAccessType,
}: {
  actualAccessType: EnumT.AccessType
  requestedAccessType: EnumT.AccessType
}) =>
  ACCESS_TYPE_ORDER.indexOf(actualAccessType) <=
  ACCESS_TYPE_ORDER.indexOf(requestedAccessType)

export const resolveGreaterAccessType = (...accessTypes: EnumT.AccessType[]) =>
  assertDefined(
    ACCESS_TYPE_ORDER.find((accessType) => accessTypes.includes(accessType))
  )

export const getAllLowerAccessTypes = memoize(
  (accessType: EnumT.AccessType) => {
    const lowerAccessTypes = newTypedArray<EnumT.AccessType>()
    let found = false
    for (const at of ACCESS_TYPE_ORDER) {
      if (found) {
        lowerAccessTypes.push(at)
      } else {
        found = at === accessType
      }
    }
    return lowerAccessTypes
  }
)
