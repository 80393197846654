<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import Icon from "./Icon.svelte"

  import { faCheck, faEdit } from "@fortawesome/free-solid-svg-icons"
  import { classNames } from "@/util"

  export let value = ""
  export let readonly: boolean = false

  let editingValue = ""
  let editing = false
  let inputEl: HTMLInputElement | null = null
  let displayEl: HTMLSpanElement | undefined

  const dispatch = createEventDispatcher<{ enter: string }>()

  const editMode = () => {
    if (readonly) {
      return
    }
    editing = true
    editingValue = value
  }

  const displayMode = () => {
    editing = false
    dispatch("enter", editingValue)
  }

  const onKeypressInput = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      displayMode()
      e.preventDefault()
    } else if (e.key === "Escape") {
      editing = false
      e.preventDefault()
    }
  }

  const onKeypressDisplay = (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.key === "Space") {
      e.preventDefault()
      editMode()
    }
  }

  $: if (editing) {
    inputEl?.focus()
  } else {
    displayEl?.focus()
  }
  $: if (inputEl)
    inputEl.addEventListener("blur", () => {
      displayMode()
    })
</script>

{#if editing}
  <div class="input-wrapper">
    <input
      class="input"
      size="10"
      bind:this={inputEl}
      type="text"
      bind:value={editingValue}
      on:keydown={onKeypressInput}
    /><span class="icon-right pointer"><Icon icon={faCheck} /></span>
  </div>
{:else}
  <span
    class={classNames("display", { pointer: !readonly })}
    tabindex={readonly ? -1 : 0}
    bind:this={displayEl}
    on:keypress={onKeypressDisplay}
    on:click={editMode}
    >{value}{#if !readonly}<span class="icon-right"><Icon icon={faEdit} /></span
      >{/if}</span
  >
{/if}

<style>
  .pointer {
    cursor: pointer;
  }
  .icon-right {
    margin-left: 6px;
  }
  .display {
    font-size: 20px;
  }
  .input {
    background: var(--secondary-bg);
    color: inherit;
    border: 2px solid rgba(0, 0, 0, 0);
    font-size: 20px;
    flex: 1;
  }
  .input:focus {
    border: 2px solid var(--action-alt);
    outline: none;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
  }
</style>
