<script lang="ts">
  import { curPath as curPathStore } from "./store.ts"
  import { SvelteComponent, onMount } from "svelte"
  import { resetPath } from "./store.ts"
  import { pickRoute } from "./util.ts"
  import type { Route } from "./types.ts"

  type T = $$Generic<any>

  export let routes: Route[]
  export let context: T | undefined = undefined

  let pageComponent: typeof SvelteComponent | null = null
  let matches: RegExpExecArray | null = null
  let hash: string = ""
  let query: URLSearchParams | null = null

  const pickPage = async () => {
    const pageResult = pickRoute(routes)
    if (pageResult) {
      matches = pageResult.matches
      query = pageResult.query
      hash = pageResult.hash
      if (pageResult.component !== undefined) {
        pageComponent = pageResult.component
      } else if (pageResult.dynamicComponent !== undefined) {
        pageComponent = (await pageResult.dynamicComponent()).default
      } else {
        console.error("unexpected undefined component")
        pageComponent = null
      }
    }
  }

  curPathStore.subscribe(() => {
    pickPage()
  })

  onMount(() => {
    window.addEventListener("popstate", () => {
      resetPath()
    })
  })
</script>

{#if matches}
  <svelte:component this={pageComponent} {matches} {hash} {query} {context} />
{:else}
  404
{/if}
