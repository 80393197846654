<script lang="ts" generics="T extends CellDataProviderBase">
  import { SvelteComponent, createEventDispatcher } from "svelte"
  import { flashElem } from "@/util"
  import type { CellKey } from "@shared/types.ts"
  import DataTableTd from "./DataTableTd.svelte"
  import CondWrapper from "../CondWrapper.svelte"
  import type {
    CellDataProviderBase,
    CellDataProviderIncluded,
  } from "./types.ts"

  type PT<CT> = CellDataProviderIncluded & Omit<CT, keyof CellDataProviderBase>
  type ET = {
    requestEdit: CustomEvent<undefined | Event>
    requestStopEdit: CustomEvent<undefined>
    requestFocus: CustomEvent<undefined>
  }
  export let cellRenderer: typeof SvelteComponent<PT<T>, ET>
  export let cellDataProvider: (cellKey: CellKey) => T

  export let columns: string[]
  export let rowId: string
  export let editing: boolean
  export let activeColId: string | null | undefined = undefined
  export let active: boolean
  export let transpose: boolean
  export let readonly: boolean

  let trElem: HTMLTableRowElement | undefined

  const dispatch = createEventDispatcher<{
    requestEdit: string
    requestStopEdit: string
    requestFocus: string
  }>()

  const doRequestEdit = (colId: string) => {
    dispatch("requestEdit", colId)
  }

  const doRequestStopEdit = (colId: string) => {
    dispatch("requestStopEdit", colId)
  }

  const doFocused = (colId: string) => dispatch("requestFocus", colId)

  export const flashRow = () => {
    if (trElem) {
      flashElem(trElem)
      trElem.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }
</script>

<tr class="tablerow datatable-row" bind:this={trElem}>
  {#each columns as colId}
    {@const cellData = cellDataProvider({ colId, rowId })}
    <CondWrapper
      wrap={!cellData.internal?.skipWrapWithTd}
      component={DataTableTd}
      {rowId}
      {colId}
    >
      <svelte:component
        this={cellRenderer}
        columnId={colId}
        {rowId}
        {transpose}
        {readonly}
        editing={activeColId === colId && editing}
        active={activeColId === colId && active}
        on:requestEdit={() => doRequestEdit(colId)}
        on:requestStopEdit={() => doRequestStopEdit(colId)}
        on:requestFocus={() => doFocused(colId)}
        {...cellData}
      />
    </CondWrapper>
  {/each}
</tr>

<style>
  .tablerow {
    transition: background-color 0.25s ease-in-out;
  }
  :global(.tablerow.flash) {
    background-color: var(--action-alt) !important;
  }
</style>
