<script lang="ts">
  import {
    faStar,
    faStarHalf,
    type IconDefinition,
  } from "@fortawesome/free-solid-svg-icons"
  import { faStar as faStarStroke } from "@fortawesome/free-regular-svg-icons"
  import Button from "./Button.svelte"

  export let value: number | undefined
  export let numStars: number = 5
  export let readonly: boolean = false
  export let nofocus: boolean = false

  type StarInfo = {
    icon: IconDefinition
    hide?: boolean
    yellow: boolean
  }

  let hoverIndex: number | null = null

  const onMouseEnter = (i: number) => () => (hoverIndex = i)
  const onMouseLeave = (i: number) => () => {
    if (hoverIndex === i) {
      hoverIndex = null
    }
  }
  const clickStar = (i: number) => () => {
    value = (scaled === i + 1 ? i + 0.5 : i + 1) / 5
  }

  $: hoverIndexResolved = readonly ? null : hoverIndex
  $: resolvedRating = value ?? 0
  $: scaled = resolvedRating * numStars
  $: starInfos = [...Array(numStars)].map((_, index): StarInfo => {
    const yellow = hoverIndexResolved == null || hoverIndexResolved >= index
    const isHovering = hoverIndexResolved != null
    if (scaled == null) {
      return {
        icon: faStarStroke,
        hide: !isHovering,
        yellow,
      }
    }
    if (scaled - index > 0.75) {
      return {
        icon: faStar,
        yellow,
      }
    }
    if (scaled - index < 0.25) {
      return {
        icon: faStarStroke,
        hide: !isHovering,
        yellow,
      }
    }
    return {
      icon: faStarHalf,
      yellow,
    }
  })
</script>

{#each starInfos as starInfo, i}
  <span
    class:yellow={starInfo.yellow}
    class:hide={starInfo.hide}
    on:mouseenter={onMouseEnter(i)}
    on:mouseleave={onMouseLeave(i)}
  >
    <Button
      on:click={clickStar(i)}
      --background-color="rgba(0,0,0,0)"
      --color={starInfo.yellow ? "yellow" : "gray"}
      iconRight={starInfo.icon}
      variant="naked"
      nofill
      nofocus={nofocus || readonly}
      {readonly}
    />
  </span>
{/each}

<style>
  .yellow {
    color: yellow;
  }
  .hide {
    opacity: 0;
  }
</style>
