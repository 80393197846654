<script lang="ts">
  import { ArrowKey, EscapeKeys } from "@/constants"

  import type { MaybeFocusable } from "@/types"

  import { classNames, keyPressWrapper } from "@/util"
  import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
  import { arithMod, newTypedArray } from "@shared/util/index.ts"
  import { createEventDispatcher, onDestroy, onMount, tick } from "svelte"
  import Icon from "./Icon.svelte"
  import FocusTrapper from "./FocusTrapper.svelte"

  export let icon: IconDefinition | undefined = undefined
  export let label: string = ""
  export let menuOpen: boolean = false
  export let alignment: "right" | "left" = "left"
  export let nofocus: boolean = false
  export let noOptions: boolean = false

  let menuElem: HTMLDivElement | undefined

  const dispatch = createEventDispatcher<{
    open: undefined
    close: undefined
  }>()

  const openMenu = (e: Event) => {
    menuOpen = !menuOpen
    if (menuOpen) {
      dispatch("open")
    } else {
      dispatch("close")
    }
    e.stopPropagation()
  }

  const doClose = () => {
    menuOpen = !menuOpen
    dispatch("close")
  }

  const menuItemElems = () => {
    const items = menuElem?.querySelectorAll(
      '[data-dropdown-item="true"]:not([data-dropdown-item-disabled="true"])'
    )
    return items
      ? (Array.from(items) as MaybeFocusable[])
      : newTypedArray<MaybeFocusable>()
  }

  const focusedItemIndex = () => {
    return menuItemElems().findIndex((elem) => elem === document.activeElement)
  }

  const focusFirstItem = async () => {
    await tick()
    const firstElem = menuItemElems()[0]
    firstElem?.focus?.()
  }

  $: if (menuOpen) focusFirstItem()

  const onKeydown = (ev: KeyboardEvent) => {
    if (!menuOpen) {
      return
    }
    if (ev.key === ArrowKey.down) {
      const currentlyFocusedIndex = focusedItemIndex() ?? -1
      const newIndex = arithMod(
        currentlyFocusedIndex + 1,
        menuItemElems().length
      )
      menuItemElems()[newIndex]?.focus?.()
      ev.stopPropagation()
      ev.preventDefault()
    } else if (ev.key === ArrowKey.up) {
      const currentlyFocusedIndex = focusedItemIndex() ?? 0
      const newIndex = arithMod(
        currentlyFocusedIndex - 1,
        menuItemElems().length
      )
      menuItemElems()[newIndex]?.focus?.()
      ev.stopPropagation()
      ev.preventDefault()
    } else if (EscapeKeys.has(ev.key)) {
      doClose()
      ev.stopImmediatePropagation()
    }
  }

  const onDocumentClick = (ev: MouseEvent) => {
    if (
      menuOpen &&
      ev.target instanceof Element &&
      !menuElem?.contains(ev.target)
    ) {
      doClose()
    }
  }

  onMount(() => {
    document.addEventListener("mouseup", onDocumentClick)
  })

  onDestroy(() => {
    document.removeEventListener("mouseup", onDocumentClick)
  })
</script>

<div class="wrapper" on:keydown={onKeydown}>
  <div
    role="button"
    tabindex={nofocus ? -1 : 0}
    on:click={openMenu}
    on:keypress={(e) => keyPressWrapper(() => openMenu(e))}
    class={classNames("open-button", { "has-children": !noOptions })}
  >
    {#if icon}<Icon {icon} />{/if}{label}
  </div>
  {#if menuOpen}<div bind:this={menuElem} class={`menu ${alignment}`}>
      <FocusTrapper on:close={doClose}>
        <slot />
      </FocusTrapper>
    </div>{/if}
</div>

<style>
  .wrapper {
    display: inline-block;
    position: relative;
    white-space: nowrap;
  }
  .has-children {
    cursor: pointer;
  }
  .open-button {
    background-color: var(--secondary-accent);
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
  }
  .menu {
    position: absolute;
    margin-top: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: var(--secondary-accent);
    border-radius: 8px;
    z-index: 300;
    -webkit-box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
  }
  .menu.left {
    left: 0;
  }
  .menu.right {
    right: 0;
  }
</style>
