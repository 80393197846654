export {
  AccessType,
  ExtractionStatus,
  DefaultColumnBehavior,
  DataType,
  IdentityProvider,
  Source,
  Field,
  UserType,
  ListingType,
} from "./main.ts"

export enum FormatSpecType {
  currency = "currency",
  geographicCoordinates = "geographic_coordinates",
  images = "images",
  checkbox = "checkbox",
  datetime = "datetime",
  source = "source",
  none = "none",
  number = "number",
  text = "text",
  rating = "rating",
  duration = "duration",
  phone = "phone",
  longText = "long_text",
}

export enum DurationUnits {
  seconds = "seconds",
  minutes = "minutes",
  hours = "hours",
  days = "days",
  weeks = "weeks",
  months = "months",
  years = "years",
}

export enum ReferenceNamespace {
  globals = "globals",
  locals = "locals",
  fields = "fields",
  columns = "columns",
}

export enum CellDataSource {
  calculated = "calculated",
  userInput = "user_input",
  extracted = "extracted",
}
