import { EnumT } from "../schema/index.ts"
import z from "zod"
import { ZGenericDataValue } from "../types.ts"

export const ZExtractedItem = z.object({
  source: z.nativeEnum(EnumT.Source),
  sourceKey: z.string(),
  data: z.array(
    z.object({
      value: ZGenericDataValue,
      field: z.nativeEnum(EnumT.Field),
    })
  ),
})
export type ExtractedItem = z.infer<typeof ZExtractedItem>
