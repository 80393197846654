<script lang="ts" context="module">
  export enum ColumnType {
    userInput = "user_input",
    advanced = "advanced",
    premium = "premium",
  }
</script>

<script lang="ts">
  import { builtins } from "@shared/lispable/functions"
  import {
    assertDefined,
    atLeastOne,
    coalesceEmptyArray,
    newTypedObject,
    objectEntries,
  } from "@shared/util"
  import InternalUi from "./InternalUI.svelte"
  import Input from "./Input.svelte"
  import GeneratorSpecEditor from "./generatorSpec/GeneratorSpecEditor.svelte"
  import FunctionSelect from "./generatorSpec/FunctionSelect.svelte"
  import PremiumColumnEditor from "./generatorSpec/PremiumColumnEditor.svelte"
  import FormatSpecEditor from "./FormatSpecEditor.svelte"
  import SingleSelect from "./SingleSelect.svelte"
  import Button from "./Button.svelte"
  import type { EnumT } from "@shared/schema"
  import { ParsedItemType } from "@shared/lispable/parsing"
  import { serialize } from "@shared/lispable/script"
  import {
    defaultFormatSpec,
    userDataTypeFormatMap,
    type UserDataType,
    columnOptions,
  } from "@/util/columns"
  import type {
    FormatSpec,
    GenericDataValue,
    PartialRecord,
    ProcessedSheetContent,
  } from "@shared/types"
  import type { OpenAddVariableModal } from "./generatorSpec/types"
  import { eed } from "@/util"

  export let sheetContent: ProcessedSheetContent
  export let openAddVariableModal: OpenAddVariableModal
  export let userDataType: UserDataType | undefined
  export let formatSpec: FormatSpec | undefined
  export let generatorSpec: string
  export let name: string
  export let columnType: ColumnType | undefined
  export let premiumFuncName: string | undefined
  export let premiumColumnValidated: boolean

  let formatSpecType: EnumT.FormatSpecType | undefined = undefined

  $: availableGeneratedColumns = objectEntries(builtins)
    .filter(([_, builtin]) =>
      atLeastOne(
        builtin.args,
        (arg) =>
          (!arg.fieldHint || usedFields.has(arg.fieldHint)) && !arg.variadric
      )
    )
    .map(([funcName]) => funcName)

  const selectPremiumColumn = (funcName: string) => () => {
    premiumFuncName = funcName
    columnType = ColumnType.premium
  }

  $: formatSpecTypeOptions = userDataType
    ? userDataTypeFormatMap[userDataType]
    : undefined

  const nameInputId = crypto.randomUUID()

  const clearFormatSpecType = (udt: UserDataType) =>
    (formatSpecType = defaultFormatSpec(udt).type)

  const setColumnType = (colType: ColumnType | undefined) => () =>
    (columnType = colType)

  $: if (userDataType) clearFormatSpecType(userDataType)

  const selectBaseFunction = (funcName: string) => {
    generatorSpec = serialize({
      type: ParsedItemType.funcCall,
      funcName,
      args: [],
    })
  }

  const reset = () => {
    setColumnType(ColumnType.userInput)()
    userDataType = undefined
    formatSpec = undefined
    formatSpecType = undefined
    name = ""
    generatorSpec = ""
  }

  $: usedFields = new Set<EnumT.Field>(
    sheetContent.extractedData.map((ed) => ed.field)
  )

  $: premiumFunc = premiumFuncName
    ? assertDefined(builtins[premiumFuncName])
    : undefined
</script>

<div class="name-row">
  <div class="field">
    <label for={nameInputId}>Name</label>
    <Input id={nameInputId} type="text" bind:value={name} />
  </div>
  <div class="reset-btn-wrapper">
    <Button on:click={reset}>Reset</Button>
    <InternalUi>
      <Button on:click={() => (columnType = ColumnType.advanced)}
        >Advanced</Button
      >
    </InternalUi>
  </div>
</div>
{#if !columnType}
  <div class="choose-column-type">
    <span>Choose column type</span>
    <div>
      <Button on:click={setColumnType(ColumnType.userInput)}>User-Input</Button>
      <Button on:click={setColumnType(ColumnType.advanced)}>Calculated</Button>
    </div>
  </div>
{:else if columnType === ColumnType.userInput}
  {#if availableGeneratedColumns.length > 0}
    <div class="columns-section">
      <div class="premium-columns-title">Premium Columns</div>
      <div class="premium-columns-list">
        {#each availableGeneratedColumns as funcName}
          <span>
            <Button on:click={selectPremiumColumn(funcName)}
              >{assertDefined(builtins[funcName]).name}</Button
            >
          </span>
        {/each}
      </div>
    </div>
  {/if}
  <div class="columns-section">
    <SingleSelect
      label="Manual Columns"
      options={columnOptions}
      bind:value={userDataType}
      placeholder="Select a column type"
      clearable
    />
    {#if coalesceEmptyArray(formatSpecTypeOptions).length > 0}
      <SingleSelect
        label="Format Type"
        options={coalesceEmptyArray(formatSpecTypeOptions)}
        bind:value={formatSpecType}
        placeholder="Select a format type (optional)"
        clearable
      />
    {/if}
    {#if formatSpecType}
      <FormatSpecEditor {formatSpecType} bind:formatSpec />
    {/if}
  </div>
{:else if columnType === ColumnType.premium && premiumFunc}
  <div class="builtin-name">{premiumFunc.name}</div>
  <PremiumColumnEditor
    {openAddVariableModal}
    {sheetContent}
    columnId={undefined}
    funcName={assertDefined(premiumFuncName)}
    bind:value={generatorSpec}
    bind:validated={premiumColumnValidated}
  />
{:else if columnType === ColumnType.advanced}
  <div class="generator-spec-wrapper">
    {#if !generatorSpec}
      <FunctionSelect value={undefined} on:change={eed(selectBaseFunction)} />
    {/if}
    <GeneratorSpecEditor
      {openAddVariableModal}
      {sheetContent}
      columnId={undefined}
      bind:value={generatorSpec}
    />
  </div>
{/if}

<InternalUi>
  <div class="generator-spec-input-wrapper">
    <Input
      type="text"
      fullwidth
      bind:value={generatorSpec}
      placeholder="Generator spec (admin only)"
    />
  </div>
</InternalUi>

<style>
  .field {
    display: flex;
    flex-direction: column;
  }
  .choose-column-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    padding: 16px;
    gap: 16px;
  }
  .name-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .generator-spec-input-wrapper {
    margin-top: 10px;
  }
  .generator-spec-wrapper {
    margin-top: 10px;
  }
  .reset-btn-wrapper {
    align-self: flex-end;
  }
  .columns-section {
    padding: 8px;
    background-color: var(--primary-bg);
    margin: 8px;
    border-radius: var(--default-rounding);
  }
  .premium-columns-list {
    text-align: center;
    padding: 8px;
  }
  .builtin-name {
    margin-top: 10px;
  }
</style>
