<script lang="ts">
  import Select from "./Select.svelte"
  import type { OptionT } from "@/types"

  type T = $$Generic<string | number>

  export let inline: boolean = false
  export let value: T | undefined
  export let options: OptionT<T>[]
  export let label: string | undefined = undefined
  export let labelId = crypto.randomUUID()
  export let placeholder: string | undefined = undefined
  export let clearable: boolean | undefined = undefined
  export let onChange: ((newValue: T) => void) | undefined = undefined
  export let fullwidth: boolean = false
  export let noborder: boolean = false
  export let disabled: boolean = false

  const onChangeInner = (newValues: T[]) => {
    if (onChange) {
      onChange(newValues[0])
    } else {
      value = newValues[0]
    }
  }

  $: values = value != undefined ? [value] : []
</script>

<Select
  multi={false}
  {values}
  {options}
  {label}
  {labelId}
  {placeholder}
  {clearable}
  {fullwidth}
  {inline}
  {noborder}
  {disabled}
  onChange={onChangeInner}
/>
