<script lang="ts">
  import RatingStars from "@/lib/RatingStars.svelte"
  import { scaleDuration, normalizeUnit } from "@/util/duration"
  import { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import { DateTime } from "luxon"
  import type { DataFormatOverrides } from "./types"

  export let textOnly: boolean = false
  export let value: GenericDataValue<EnumT.DataType.number> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}

  $: displayedValue = ((): string => {
    if (value == null) {
      return ""
    }
    switch (formatSpec.type) {
      case EnumT.FormatSpecType.none:
      case EnumT.FormatSpecType.number:
        return value.toString()
      case EnumT.FormatSpecType.currency:
        return new Intl.NumberFormat(undefined, {
          style: "currency",
          currency: formatSpec.currencyCode,
        }).format(value)
      case EnumT.FormatSpecType.duration:
        const scaled = scaleDuration({
          durationSeconds: value,
          minimumUnits: formatSpec.minimumUnits,
        })
        if (
          formatSpec.minimumUnits &&
          scaled.get(formatSpec.minimumUnits) === 0
        ) {
          return new Intl.NumberFormat(undefined, {
            style: "unit",
            unit: normalizeUnit(formatSpec.minimumUnits),
            unitDisplay: "long",
          }).format(0)
        }
        return scaled.toHuman()
      case EnumT.FormatSpecType.datetime:
        return DateTime.fromSeconds(value).toLocaleString(
          formatSpec.includeTime ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT
        )
      case EnumT.FormatSpecType.rating:
        return `${(Math.round(value * 5 * 10) / 10).toFixed(1)}/5`
      default:
        return `ERR: unsupported format type: ${formatSpec.type}`
    }
  })()
</script>

{#if value != null}
  {#if textOnly}
    {displayedValue}
    <!--Put component overrides for the display value here-->
  {:else if formatSpec.type === EnumT.FormatSpecType.rating}
    <RatingStars readonly {value} />
  {:else}
    {displayedValue}
  {/if}
{/if}
