import { EnumT } from "@shared/schema"
import { Duration } from "luxon"

const orderedDurations = [
  EnumT.DurationUnits.seconds,
  EnumT.DurationUnits.minutes,
  EnumT.DurationUnits.hours,
  EnumT.DurationUnits.days,
  EnumT.DurationUnits.weeks,
  EnumT.DurationUnits.months,
  EnumT.DurationUnits.years,
]

const normalizationMap: Record<EnumT.DurationUnits, string> = {
  [EnumT.DurationUnits.seconds]: "second",
  [EnumT.DurationUnits.minutes]: "minute",
  [EnumT.DurationUnits.hours]: "hour",
  [EnumT.DurationUnits.days]: "day",
  [EnumT.DurationUnits.weeks]: "week",
  [EnumT.DurationUnits.months]: "month",
  [EnumT.DurationUnits.years]: "year",
}

export const normalizeUnit = (unit: EnumT.DurationUnits) =>
  normalizationMap[unit].toLowerCase()

export const scaleDuration = ({
  durationSeconds,
  minimumUnits,
}: {
  durationSeconds: number
  minimumUnits?: EnumT.DurationUnits
}) => {
  const minIndex = orderedDurations.indexOf(
    minimumUnits ?? EnumT.DurationUnits.seconds
  )
  const filteredMap = {
    milliseconds: 0,
    ...Object.fromEntries(
      orderedDurations.slice(0, minIndex).map((durUnit) => [durUnit, 0])
    ),
  }
  return Duration.fromObject({ seconds: durationSeconds })
    .rescale()
    .set(filteredMap)
    .rescale()
}
