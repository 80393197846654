<script lang="ts">
  import type { Variant } from "@/types"
  import { classNames, keyPressWrapper } from "@/util"

  import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
  import { createEventDispatcher } from "svelte"

  import Icon from "./Icon.svelte"

  export let icon: IconDefinition | undefined = undefined
  export let variant: Variant = "default"
  export let disabled: boolean = false

  const dispatch = createEventDispatcher<{
    click: Event
  }>()

  const onClick = (e: Event) => {
    if (disabled) {
      return
    }
    e.stopPropagation()
    e.stopImmediatePropagation()
    e.preventDefault()
    dispatch("click", e)
  }

  const onKeydown = (e: KeyboardEvent) => keyPressWrapper(() => onClick(e))(e)
</script>

<div
  role="button"
  data-dropdown-item="true"
  data-dropdown-item-disabled={disabled}
  tabindex={disabled ? -1 : 0}
  on:click={onClick}
  on:keydown={onKeydown}
  class={classNames("wrapper", variant, { disabled })}
>
  {#if icon}
    <div class="icon">
      <Icon {icon} />
    </div>
  {/if}
  <slot />
</div>

<style>
  .icon {
    width: 20px;
    text-align: center;
    display: inline-block;
  }
  .wrapper {
    white-space: nowrap;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: left;
  }
  .wrapper:hover:not(.disabled) {
    filter: brightness(90%);
  }
  .wrapper:active {
    filter: brightness(80%);
  }
  .wrapper.default:hover:not(.disabled),
  .wrapper.default:focus {
    background-color: var(--secondary-accent);
  }
  .wrapper:focus {
    outline: 2px solid var(--action-alt-fg);
  }
  .wrapper.danger:hover:not(.disabled),
  .wrapper.danger:focus {
    background-color: var(--primary-danger);
  }
  .wrapper.disabled {
    filter: brightness(0.75);
    cursor: not-allowed;
  }
</style>
