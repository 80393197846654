<script lang="ts">
  import { ArrowKey, EscapeKeys } from "@/constants"
  import type { MaybeFocusable, OptionT } from "@/types"
  import {
    classNames,
    debounce,
    isCommonKey,
    keyPressWrapper,
    getScrollParent,
  } from "@/util"
  import {
    faChevronDown,
    faChevronUp,
    faClose,
  } from "@fortawesome/free-solid-svg-icons"
  import { arithMod } from "@shared/util/index.ts"
  import { DateTime } from "luxon"
  import { onMount, tick } from "svelte"
  import Icon from "./Icon.svelte"

  type T = $$Generic<string | number>
  export let options: OptionT<T>[]
  export let values: T[]
  export let label: string | undefined = undefined
  export let labelId = crypto.randomUUID()
  export let placeholder: string | undefined = undefined
  export let multi: boolean
  export let clearable: boolean | undefined = undefined
  export let onChange: ((newValues: T[]) => void) | undefined = undefined
  export let fullwidth: boolean = false
  export let inline: boolean = false
  export let noborder: boolean = false
  export let disabled: boolean = false

  let hasExpandedOnce = false
  let expanded = false
  let activeOptionIndex: number | undefined = undefined
  let listbox: HTMLDivElement | undefined
  let openButton: HTMLDivElement | undefined
  let filter: string = ""
  let filterTimer: DateTime = DateTime.now()
  let timerHandle: ReturnType<typeof setTimeout> | undefined = undefined

  const NO_OPTIONS_VALUE = "7ea3ff5c-ae97-4b34-8d02-6fb93ff3dc57"
  const NO_OPTIONS_OPTION: OptionT<T> = {
    label: "No options",
    value: NO_OPTIONS_VALUE as T,
  }

  $: selectedOption = options.find((option) =>
    values.some((val) => val === option.value)
  )

  export const expand = () => {
    if (disabled) {
      return
    }
    expanded = true
  }
  export const collapse = () => {
    activeOptionIndex = undefined
    expanded = false
    resetFilter()
  }
  const toggleExpanded = () => (expanded ? collapse() : expand())
  const clear = (ev?: MouseEvent) => {
    doOnChange([])
    collapse()
    ev?.stopPropagation()
  }
  const doOnChange = (newValues: T[]) => {
    if (onChange) {
      onChange(newValues)
    } else {
      values = newValues
    }
  }
  const selectOption = (option: OptionT<T>) => () => {
    if (option.value === NO_OPTIONS_VALUE) {
      return
    }

    const currentIndex = values.findIndex((value) => value === option.value)
    let valuesToUpdate: T[] = []
    if (multi) {
      if (currentIndex >= 0) {
        valuesToUpdate = [
          ...values.slice(0, currentIndex),
          ...values.slice(currentIndex + 1),
        ]
      }
      valuesToUpdate = [...values, option.value]
    } else {
      valuesToUpdate = [option.value]
      collapse()
    }
    doOnChange(valuesToUpdate)
  }

  const handleFocusLoss = ({
    relatedTarget,
    currentTarget,
  }: {
    relatedTarget: unknown
    currentTarget: HTMLElement
  }) => {
    if (
      relatedTarget instanceof HTMLElement &&
      currentTarget.contains(relatedTarget)
    ) {
      return
    }
    collapse()
  }

  const onWrapperKeypress = (ev: KeyboardEvent) => {
    if (ev.key === ArrowKey.up) {
      if (!expanded) {
        expand()
        return
      }
      activeOptionIndex =
        activeOptionIndex === undefined
          ? 0
          : arithMod(activeOptionIndex - 1, options.length)
      ev.stopPropagation()
      ev.preventDefault()
    } else if (ev.key === ArrowKey.down) {
      if (!expanded) {
        expand()
        return
      }
      activeOptionIndex =
        activeOptionIndex === undefined
          ? 0
          : arithMod(activeOptionIndex + 1, options.length)
      ev.stopPropagation()
      ev.preventDefault()
    } else if (EscapeKeys.has(ev.key)) {
      collapse()
      openButton?.focus()
      ev.stopPropagation()
    } else if (isCommonKey(ev.key)) {
      if (!expanded) {
        expand()
      }
      filter += ev.key
      activeOptionIndex = options.findIndex(
        (option) =>
          typeof option.label === "string" &&
          option.label.toLowerCase().startsWith(filter.toLowerCase())
      )
    }
  }

  $: if (expanded) {
    if (multi) {
      activeOptionIndex = options.length > 0 ? 0 : undefined
    } else {
      activeOptionIndex =
        values.length > 0
          ? options.findIndex((option) => option.value === values[0])
          : options.length > 0
          ? 0
          : undefined
    }
  }

  $: if (!expanded && hasExpandedOnce) {
    openButton?.focus()
  }

  const resetFilter = () => {
    filter = ""
    if (timerHandle) {
      clearTimeout(timerHandle)
    }
  }

  const resetFilterTimer = () => {
    filterTimer = DateTime.now()
    if (timerHandle) {
      clearTimeout(timerHandle)
    }
    timerHandle = setTimeout(() => {
      if (Math.abs(filterTimer.diffNow().toMillis()) > 1200) {
        resetFilter()
      }
    }, 1500)
  }

  $: if (filter) {
    resetFilterTimer()
  }

  $: if (values) {
    resetFilter()
  }

  $: if (activeOptionIndex !== undefined) {
    const elem = listbox?.children[activeOptionIndex] as
      | MaybeFocusable
      | undefined
    elem?.focus?.()
  }

  const checkSelectHeight = async () => {
    await tick()
    if (!listbox) {
      return
    }
    const viewportHeight = window.innerHeight
    const rect = listbox.getBoundingClientRect()
    listbox.style.maxHeight = `${Math.min(
      Math.abs(viewportHeight - rect.y),
      300
    )}px`

    if (openButton) {
      const sp = getScrollParent(openButton)
      const listboxRect = listbox.getBoundingClientRect()
      const parentRect = sp?.getBoundingClientRect()
      if (
        parentRect &&
        listboxRect.y + listboxRect.height > parentRect.y + parentRect.height
      ) {
        openButton?.scrollIntoView()
      }
    }
  }

  $: if (expanded) {
    hasExpandedOnce = true
    checkSelectHeight()
  }
  onMount(() =>
    new ResizeObserver(debounce(checkSelectHeight)).observe(document.body)
  )

  $: filteredOptions = options.filter(({ value }) => !values.includes(value))
  $: displayedOptions =
    filteredOptions.length > 0 ? filteredOptions : [NO_OPTIONS_OPTION]
</script>

<!-- prettier-ignore -->
<div
  class={classNames("wrapper", { fullwidth, inline, disabled })}
  on:focusout={handleFocusLoss}
  on:keydown={onWrapperKeypress}
>{#if label}<span id={labelId}>{label}</span>{/if}<div
    class={classNames("open-button", {noborder})}
    class:open={expanded}
    bind:this={openButton}
    role="button"
    tabindex={disabled ? -1 : 0}
    on:click={toggleExpanded}
    on:keypress={keyPressWrapper(toggleExpanded)}
  ><span class="open-button-content">{#if typeof selectedOption?.label === "string"}<span class="option-chip selected-option">{selectedOption.label}</span>{:else if selectedOption !== undefined}<svelte:component
          this={selectedOption.label}
          value={selectedOption.value}
        />{:else}<span class="option-chip placeholder">{placeholder ?? ""}</span>{/if}</span>{#if clearable && values.length > 0}<div
        role="button"
        tabindex="0"
        on:click={clear}
        on:keypress={keyPressWrapper(clear)}
        class="clear-button"
      ><Icon icon={faClose} /></div>{/if}<span class="chevron"
      ><Icon icon={expanded ? faChevronUp : faChevronDown} /></span
    ></div>{#if expanded}<div
      class="listbox"
      bind:this={listbox}
      role="listbox"
      aria-expanded={expanded}
    >{#each displayedOptions as option}<div
          role="option"
          class="option"
          class:no-options={option.value === NO_OPTIONS_VALUE}
          tabindex="0"
          on:click={selectOption(option)}
          on:keypress={keyPressWrapper(selectOption(option))}
          aria-selected={values.some((val) => val === option.value)}
        >{#if typeof option?.label === "string"}<span class="option-label">{option.label}</span>{:else if option !== undefined}<svelte:component this={option.label} value={option.value} />{/if}</div>{/each}</div>{/if}</div>

<style>
  .option {
    background-color: var(--secondary-bg);
    padding: 5px;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  .option:focus {
    background-color: var(--primary-bg);
  }
  .option:not(.no-options):hover {
    opacity: 0.7;
  }
  .wrapper {
    position: relative;
  }
  .clear-button {
    cursor: pointer;
    margin-right: 6px;
    display: flex;
    align-items: center;
  }
  .open-button {
    min-width: 50px;
    user-select: none;
    display: flex;
    flex-direction: row;
    padding: 5px;
    outline: none;
    font-size: 15px;
  }
  .open-button:not(.noborder) {
    border: 2px solid var(--secondary-accent);
    border-radius: 8px;
  }
  .wrapper.fullwidth {
    flex: 1;
  }
  .wrapper.inline {
    display: inline-block;
  }
  .open-button:focus {
    border-color: var(--action-alt);
  }
  .open-button.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-color: var(--action-alt);
    border-left-color: var(--action-alt);
    border-right-color: var(--action-alt);
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  .open-button-content {
    flex-grow: 1;
  }
  .listbox {
    position: absolute;
    top: 100%;
    margin-top: -1px;
    left: 0;
    right: 0;
    background-color: var(--primary-bg);
    border: 2px solid var(--action-alt);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    z-index: 9999;
    overflow-y: auto;
  }
  .option-chip {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 5px;
    display: inline-block;
  }
  .placeholder {
    opacity: 0.8;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .option-label {
    margin-left: 4px;
  }
  .selected-option {
    background-color: var(--action-alt);
    color: white;
  }
  .chevron {
    align-self: center;
    margin-left: 4px;
  }
  .wrapper.disabled {
    pointer-events: none;
    filter: brightness(0.75);
  }
</style>
