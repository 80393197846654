import z from "zod"

export const ZSheetInfo = z.object({
  sheetProperties: z.object({
    id: z.string().uuid(),
    name: z.string(),
    created: z.coerce.date(),
    updated: z.coerce.date(),
  }),
  lastActivity: z.coerce.date(),
  numItems: z.number(),
  thumbnails: z.array(z.string()),
})
export type SheetInfo = z.infer<typeof ZSheetInfo>
