<script lang="ts">
  import Modal from "./Modal.svelte"
  import type { ModalManagerT } from "./index.ts"

  export let modalManager: ModalManagerT

  $: modals = modalManager.modals

  const close = (id: string) => () => modalManager.closeModal(id)
</script>

{#each $modals as modal}
  <Modal
    on:close={close(modal.id)}
    title={modal.title}
    description={modal.description}
    fullscreen={modal.fullscreen}
    showX={modal.showX}
    closeWith={modal.closeWith}
    onClose={close(modal.id)}
  >
    <svelte:component
      this={modal.component}
      on:close={close(modal.id)}
      onAccept={modal.onAccept}
      {...modal.props}
    />
  </Modal>
{/each}
