<script lang="ts">
  import { beforeUpdate } from "svelte"
  import {
    icon as faIcon,
    type IconDefinition,
  } from "@fortawesome/fontawesome-svg-core"

  export let icon: IconDefinition | null

  let html = ""

  beforeUpdate(() => {
    if (!icon) {
      return
    }
    const result = faIcon(icon)
    if (!result) {
      console.error(`unable to render icon ${icon}`)
      return
    }
    html = result.html as unknown as string
  })
</script>

{#if icon}
  {@html html}
{/if}
