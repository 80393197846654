<script lang="ts" context="module">
  export type AcceptEditColumn = {
    formatSpec: FormatSpec
    generatorSpec: string | undefined
    name: string
  }

  const nameInputId = crypto.randomUUID()
</script>

<script lang="ts">
  import { builtins } from "@shared/lispable/functions.ts"

  import type {
    ElementOf,
    FormatSpec,
    GenericDataValue,
    PartialRecord,
    ProcessedSheetContent,
  } from "@shared/types.ts"
  import { assertDefined, newTypedObject } from "@shared/util/index.ts"
  import Button from "../Button.svelte"
  import type { OpenAddVariableModal } from "../generatorSpec/types.ts"
  import SidepanelContent from "./SidepanelContent.svelte"
  import PremiumColumnEditor from "../generatorSpec/PremiumColumnEditor.svelte"
  import { sheetContentFactory } from "@/stores/sheetStore.ts"
  import { getColumnDefinitionForCol } from "@shared/sheet/index.ts"
  import FormatSpecEditor from "../FormatSpecEditor.svelte"
  import { FormatSpecType } from "@shared/schema/enum.ts"
  import { tryToParse } from "@shared/lispable/script.ts"
  import { ParsedItemType } from "@shared/lispable/parsing.ts"
  import Input from "../Input.svelte"

  export let sheetId: string
  export let columnId: string
  export let accept: (arg: AcceptEditColumn) => void
  export let openAddVariableModal: OpenAddVariableModal

  $: sheetContent = $sheetContentFactory(sheetId)
  $: colDef = sheetContent
    ? getColumnDefinitionForCol({
        sheetContent: sheetContent,
        colId: columnId,
      })
    : undefined
  $: column = sheetContent?.columns.find((c) => c.id === columnId)

  let formatSpec: FormatSpec | undefined
  let generatorSpec: string = ""
  let name: string = ""

  const updateLocalsColDef = (
    colDef: ElementOf<ProcessedSheetContent["columnDefinitions"]>
  ) => {
    formatSpec = colDef.formatSpec
    generatorSpec = colDef.generatorSpec ?? ""
  }
  const updateLocalsCol = (
    col: ElementOf<ProcessedSheetContent["columns"]>
  ) => {
    name = col.name
  }

  $: if (colDef) updateLocalsColDef(colDef)
  $: if (column) updateLocalsCol(column)

  $: parsedGeneratorSpec = tryToParse(generatorSpec)

  $: premiumFuncName =
    parsedGeneratorSpec?.type === ParsedItemType.funcCall &&
    parsedGeneratorSpec.funcName
  $: premiumFunc = premiumFuncName
    ? assertDefined(builtins[premiumFuncName])
    : undefined

  let premiumColumnValidated: boolean = false

  const doAccept = () => {
    accept({
      formatSpec: formatSpec ?? { type: FormatSpecType.none },
      generatorSpec: !!generatorSpec ? generatorSpec : undefined,
      name,
    })
  }

  $: acceptDisabled = !name || (!!generatorSpec && !premiumColumnValidated)
</script>

<SidepanelContent>
  <div class="content">
    <div class="field">
      <label for={nameInputId}>Name</label>
      <Input id={nameInputId} type="text" bind:value={name} />
    </div>
    {#if formatSpec}
      <FormatSpecEditor formatSpecType={formatSpec.type} bind:formatSpec />
    {/if}
    {#if premiumFunc && sheetContent}
      <PremiumColumnEditor
        {openAddVariableModal}
        {sheetContent}
        columnId={undefined}
        funcName={assertDefined(premiumFuncName || null)}
        bind:value={generatorSpec}
        bind:validated={premiumColumnValidated}
      />
    {/if}
  </div>
  <div slot="footer" class="footer">
    <Button variant="success" disabled={acceptDisabled} on:click={doAccept}
      >Accept</Button
    >
  </div>
</SidepanelContent>

<style>
  @media only screen and (min-width: 770px) {
    .content {
      min-width: 450px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .field {
    display: flex;
    flex-direction: column;
  }
</style>
