<script lang="ts">
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import { DataFormatOverrideType, type DataFormatOverrides } from "./types"
  import { EnumT } from "@shared/schema"
  import Input from "@/lib/Input.svelte"
  import DisplayText from "./DisplayText.svelte"

  export let value: GenericDataValue<EnumT.DataType.text> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
  export let autofocus: boolean = false
</script>

{#if formatSpec.type === EnumT.FormatSpecType.source}
  <!--Falling back to just display the source since we don't really need to implement editing here-->
  <DisplayText {value} {formatSpec} {dataFormatOverrides} />
{:else}
  <!--TODO: support long form text-->
  <Input
    type="text"
    on:blur={dataFormatOverrides[DataFormatOverrideType.onBlur]}
    on:keydown={dataFormatOverrides[DataFormatOverrideType.onKeydown]}
    {autofocus}
    bind:value
    fullwidth
  />
{/if}
