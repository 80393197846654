import type { EnumT } from "../schema/index.ts"
import type { Edges, Json, PartialRecord, ReferenceBase } from "../types.ts"

export * from "./math.ts"

export const safeParseInt = (intStr: string, radix?: number) => {
  const retVal = parseInt(intStr, radix)
  return isNaN(retVal) ? null : retVal
}

export const safeParseFloat = (floatStr: string | null | undefined) => {
  if (floatStr == null) {
    return null
  }
  const retVal = parseFloat(floatStr)
  return isNaN(retVal) ? null : retVal
}

const charsToKeep = new Set([
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "-",
  "+",
  "x",
  "X",
  ".",
])

export const parseIntBestEffort = (intStr: string, radix?: number) => {
  let strToParse = ""
  for (let i = 0; i < intStr.length; i++) {
    if (charsToKeep.has(intStr[i])) {
      strToParse += intStr[i]
    }
  }
  return safeParseInt(strToParse, radix)
}

export const newTypedArray = <T>(init?: T[]) => {
  return init ?? ([] as T[])
}

export const newTypedObject = <T extends symbol | number | string, V>(
  init?: Partial<Record<T, V>>
) => {
  return init ?? ({} as Partial<Record<T, V>>)
}

export const coalesceEmptyLookup = <K extends string | number | symbol, V>(
  init?: Partial<Record<K, V>> | null | undefined
) => {
  if (init === null || init === undefined) {
    return {} as Partial<Record<K, V>>
  }
  return init
}

export const coalesceEmptyObject = <T>(init?: T | null | undefined) => {
  return init ?? ({} as Partial<T>)
}

export const coalesceEmptyArray = <T>(init?: T[]) => {
  if (init === null || init === undefined) {
    return newTypedArray<T>()
  }
  return init
}

export const objectKeys = <K extends string | number | symbol, V>(
  obj: PartialRecord<K, V>
) => Object.keys(obj) as K[]

export const objectEntries = <K extends string | number | symbol, V>(
  obj: Partial<Record<K, V>>
) => {
  return Object.entries(obj) as [K, V][]
}

export const objectValues = <K extends string | number | symbol, V>(
  obj: Partial<Record<K, V>>
) => Object.values(obj) as V[]

// Calculates a random integer in [low, high] (both inclusive)
export const randIntInRange = (low: number, high: number) => {
  const min = Math.ceil(low)
  const max = Math.floor(high)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const assertDefined = <T>(v: T | undefined | null) => {
  if (v === null) {
    throw new Error("null variable")
  }
  if (v === undefined) {
    throw new Error("undefined variable")
  }
  return v
}

export { Location } from "./location.ts"

interface SourceRefKeyLike {
  sourceRefKey: EnumT.Source
  sourceKey: string

  [x: string | number | symbol]: unknown
}

export const sourceRefKey = ({ sourceRefKey, sourceKey }: SourceRefKeyLike) =>
  JSON.stringify({ sourceRefKey, sourceKey })

export const buildLookup = <T, K extends keyof T & string>(
  items: T[],
  key: K
) =>
  items.reduce(
    (lookup, item) => ({
      ...lookup,
      [item[key] as unknown as string]: item,
    }),
    newTypedObject<string, T>()
  )

export const isNullish = <T>(v: T | undefined | null) =>
  v !== undefined && v !== null

const deterministicReplacer = (_: unknown, v: unknown) =>
  typeof v !== "object" || v === null || Array.isArray(v)
    ? v
    : Object.fromEntries(
        Object.entries(v).sort(([ka], [kb]) => (ka < kb ? -1 : ka > kb ? 1 : 0))
      )

export const stringifyJSONKey = <
  T extends PartialRecord<string | number | symbol, unknown>
>(
  obj: T
) => JSON.stringify(obj, deterministicReplacer)

// IMPROVE: support numeric keys
export const newDefaultDict = <K extends string | symbol, V>(
  initializer: () => V
): Record<K, V> => {
  const handler = {
    get: function (target: PartialRecord<K, V>, name: K): V {
      if (!target[name]) {
        target[name] = initializer()
      }
      return target[name] as V
    },
  }
  return new Proxy(newTypedObject<K, V>(), handler) as Record<K, V>
}

export const topologicalSort = <
  KT extends string | symbol,
  RT extends ReferenceBase<KT>,
  NT
>(
  nodes: PartialRecord<KT, NT>,
  edgesPassed: Edges<RT, KT>
) => {
  if (objectKeys(nodes).length === 0) {
    return newTypedArray<NT>()
  }
  const L = newTypedArray<NT>()
  const S = new Set<KT>(objectKeys(nodes))
  const edges = newTypedObject<KT, RT[]>()
  const edgesReversed = newDefaultDict<KT, Set<KT>>(() => new Set())
  for (const [key, refList] of objectEntries(edgesPassed)) {
    edges[key] = [...refList]
    refList.forEach((ref) => {
      edgesReversed[ref.to].add(key)
      S.delete(ref.to)
    })
  }
  if (S.size === 0) {
    throw "not a DAG"
  }
  const unreferencedNodes = [...S].sort()
  while (unreferencedNodes.length > 0) {
    const nodeId = assertDefined(unreferencedNodes.pop())
    const node = assertDefined(nodes[nodeId])
    L.push(node)
    const refList = coalesceEmptyArray(edges[nodeId])
    while (refList.length > 0) {
      const ref = assertDefined(refList.pop())
      edgesReversed[ref.to].delete(nodeId)
      if (edgesReversed[ref.to].size === 0) {
        S.add(ref.to)
      }
    }
  }
  if (objectValues(edgesReversed).find((refSet) => refSet.size > 0)) {
    throw "cycle detected"
  }
  return L
}

export const setDifferenceImmutable = <T>(s1: Set<T>, s2: Set<T>) =>
  new Set<T>([...s1].filter((item) => !s2.has(item)))

export const setDifferenceMutable = <T>(s1: Set<T>, s2: Set<T>) => {
  for (const item of s1) {
    if (s2.has(item)) {
      s1.delete(item)
    }
  }
  return s1
}

export const inRange = <T>(
  arr: T[],
  testFn: (item: T) => boolean,
  min: number,
  max?: number
) => {
  let numFound = 0
  for (const item of arr) {
    if (testFn(item)) {
      numFound++
    }
  }
  return (
    (max != null && numFound <= max && numFound >= min) ||
    (max == null && numFound >= min)
  )
}

export const atLeastOne = <T>(arr: T[], testFn: (item: T) => boolean) =>
  inRange(arr, testFn, 1)

export const assertNever = (arg: never): never => {
  throw new Error(`unexpected never type ${JSON.stringify(arg)}`)
}

export const exceptionToString = (err: unknown): string | undefined => {
  if (typeof err === "string") {
    return err
  } else if (err instanceof Error) {
    return err.message
  }
}

const getMemoizeKey = (...args: unknown[]): string => {
  if (args.length === 0) {
    return "EMPTY_ARGS"
  }
  // IMPROVE: This is probably an unnecessarily slow operation
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return stringifyJSONKey(args as any)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const memoize = <FT extends (...args: any[]) => unknown>(fn: FT): FT => {
  const cache = newTypedObject<string, unknown>()

  const memoized: FT = ((...args: unknown[]) => {
    const key = getMemoizeKey(args)
    if (key in cache) {
      return cache[key]
    }
    const result = fn(...args)
    cache[key] = result
    return result
  }) as unknown as FT

  return memoized
}

export const typedParseJson = (jsonString: string): Json =>
  JSON.parse(jsonString) as Json

export const getBaseUrl = (url: URL) => {
  const modifiedUrl = new URL(url)
  modifiedUrl.hash = ""
  modifiedUrl.search = ""
  modifiedUrl.pathname = ""
  return modifiedUrl
}
