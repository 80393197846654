<script lang="ts">
  import { globalShareModal } from "@/lib/modals"

  import {
    faExclamationCircle,
    faShareAlt,
  } from "@fortawesome/free-solid-svg-icons"
  import { canShare, doShare } from "."
  import Button from "../Button.svelte"
  import type { ShareableData } from "./types.ts"
  import { bottomRightToastManager } from "../ToastManager/index.ts"

  export let shareData: ShareableData
  export let disableFallbackModal: boolean = false

  const onClick = async () => {
    if (await canShare(shareData)) {
      try {
        await doShare(shareData)
      } catch (e) {
        console.error(e)
      }
      return
    } else if (!disableFallbackModal) {
      globalShareModal.open({
        props: { value: shareData.url ?? shareData.text },
      })
    } else {
      bottomRightToastManager.pushToast({
        message: "Sharing failed",
        variant: "danger",
        icon: faExclamationCircle,
      })
    }
  }
</script>

{#if $$slots.default}
  <Button iconRight={faShareAlt} on:click={onClick}><slot /></Button>
{:else}
  <Button iconRight={faShareAlt} on:click={onClick} />
{/if}
