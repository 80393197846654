<script lang="ts">
  import { eed } from "@/util"

  import type { ParsedItem } from "@shared/lispable/parsing"
  import { serialize, tryToParse } from "@shared/lispable/script"
  import type { ProcessedSheetContent } from "@shared/types.ts"
  import ParsedItemControl from "./ParsedItemControl.svelte"
  import type { OpenAddVariableModal } from "./types.ts"

  export let sheetContent: ProcessedSheetContent
  export let value: string | undefined
  export let columnId: string | undefined
  export let openAddVariableModal: OpenAddVariableModal

  $: parsedScript = value ? tryToParse(value) : undefined

  const onChange = (parsedItem: ParsedItem) => (value = serialize(parsedItem))
</script>

<div>
  {#if parsedScript}
    <ParsedItemControl
      on:change={eed(onChange)}
      {openAddVariableModal}
      {columnId}
      {sheetContent}
      item={parsedScript}
    />
  {/if}
</div>
