<script lang="ts">
  import type { Size, Variant } from "@/types"
  import type { IconDefinition } from "@fortawesome/free-solid-svg-icons"
  import CondWrapper from "./CondWrapper.svelte"
  import Icon from "./Icon.svelte"
  import RouterLink from "./RouterView/RouterLink.svelte"

  export let to: string | undefined = undefined
  export let size: Size = "default"
  export let iconLeft: IconDefinition | undefined = undefined
  export let iconRight: IconDefinition | undefined = undefined
  export let variant: Variant = "default"
  export let variants: Variant[] = []
  export let active: boolean = false
  export let disabled: boolean = false
  export let readonly: boolean = false
  export let nofill: boolean = false
  export let nofocus: boolean = false
  export let autofocus: boolean = false

  let computedVariantClasses: string[] = []

  $: computedVariantClasses = [...variants, variant]
    .filter((v) => !!v)
    .map((v) => `variant-${v}`)
</script>

<CondWrapper {to} wrap={!!to} {nofocus} component={RouterLink}>
  <button
    on:click
    class={`size-${size} ${computedVariantClasses.join(" ")}`}
    class:active
    class:readonly
    tabindex={nofocus ? -1 : 0}
    {disabled}
    {autofocus}
    >{#if iconLeft}<Icon
        icon={iconLeft}
      />{#if $$slots.default}&nbsp;{/if}{/if}<slot
    />{#if iconRight}{#if $$slots.default}&nbsp;{/if}<Icon
        icon={iconRight}
      />{/if}
  </button>
</CondWrapper>

<style>
  :root {
    --color: white;
    --background-color: black;
  }
  button {
    white-space: pre;
  }
  button {
    cursor: pointer;
    color: var(--primary-fg);
    border: none;
    border-radius: 8px;
    color: var(--color);
    background-color: var(--background-color);
  }
  button:not(.variant-naked) {
    font-weight: 700;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  button:focus {
    outline-color: var(--background-color);
    outline: 1.5px;
    outline-style: dotted;
  }
  button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  button.variant-action {
    --color: var(--action-fg);
    --background-color: var(--action-bg);
  }
  button.variant-action-alt {
    --color: var(--action-alt-fg);
    --background-color: var(--action-alt-bg);
  }
  button.variant-success {
    --color: var(--success-fg);
    --background-color: var(--success-bg);
  }
  button.variant-danger {
    --color: var(--danger-fg);
    --background-color: var(--danger-bg);
  }
  button.variant-default {
    --background-color: var(--default-bg);
    --color: var(--default-fg);
  }
  button.size-small {
    font-size: 12px;
  }
  button.size-default {
    font-size: 16px;
  }
  button.size-inherit {
    font-size: inherit;
  }
  button.size-large {
    font-size: 22px;
    padding-left: 20px;
    padding-right: 20px;
  }
  button:hover:not(:disabled),
  .active {
    filter: brightness(90%);
  }
  button:active:not(:disabled) {
    filter: brightness(70%);
  }
  button.readonly:focus {
    outline: none;
  }
  button.readonly {
    cursor: unset;
    pointer-events: none;
  }
  button.readonly:active {
    filter: unset;
  }
  button.readonly:hover {
    filter: unset;
  }
</style>
