import { z } from "zod"

export const ZProxyConfig = z.object({
  jsRender: z.optional(z.boolean()),
  antibot: z.optional(z.boolean()),
  device: z.optional(z.union([z.literal("desktop"), z.literal("mobile")])),
  customHeaders: z.optional(z.boolean()),
  addedHeaders: z.optional(z.object({})),
})
export type ProxyConfig = z.infer<typeof ZProxyConfig>
