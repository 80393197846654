<script lang="ts">
  import { classNames, keyPressWrapper } from "@/util"
  import { createEventDispatcher } from "svelte"
  import type { ChangeEventHandler } from "svelte/elements"
  import Icon from "../Icon.svelte"
  import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

  export let chosen: boolean = false
  export let anyChosen: boolean | undefined = undefined
  export let groupName: string
  export let ariaDescription: string
  export let disabled: boolean = false
  export let clearable: boolean = false
  export let hideCheck: boolean = false

  const dispatch = createEventDispatcher<{ choose: void; unchoose: void }>()

  const onChoose = () => {
    if (disabled) {
      return
    }
    dispatch("choose")
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement
    if (target.checked) {
      onChoose()
    }
  }

  const onLabelClick = (e: Event) => {
    if (chosen && clearable) {
      dispatch("unchoose")
    } else if (!chosen) {
      e.preventDefault()
      onChoose()
    }
  }

  $: otherChosen = !chosen && anyChosen
</script>

<label
  class={classNames({ chosen, "other-chosen": otherChosen, disabled }, "card")}
  tabindex="0"
  on:keypress={(e) => keyPressWrapper(onLabelClick, e)(e)}
  on:click={onLabelClick}
>
  {#if !hideCheck}
    <div class={classNames({ chosen }, "check-wrapper")}>
      <div class={classNames({ chosen }, "check")}>
        <div class="check-inner">
          <Icon icon={faCheckCircle} />
        </div>
      </div>
    </div>
  {/if}
  <input
    class="radio hidden-visually"
    tabindex="-1"
    name={groupName}
    type="radio"
    checked={chosen}
    {disabled}
    on:input={onChange}
    on:click={onChange}
  />
  <span class="hidden-visually">{ariaDescription}</span>
  <slot />
</label>

<style>
  .hidden-visually {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .card {
    border-radius: 1em;
    display: inline-block;
    position: relative;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
    display: grid;
  }
  .card:not(.disabled) {
    cursor: pointer;
  }
  .card.chosen {
    box-shadow: 0px 0px 8px 8px var(--action-fg);
    outline: 2px solid var(--action-fg);
  }
  .card:not(.disabled):not(.chosen):hover {
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.2);
  }
  .card.disabled {
    filter: brightness(0.75);
  }
  .card.other-chosen {
    filter: brightness(0.85);
  }
  .card.disabled {
    cursor: not-allowed;
  }
  .check-wrapper {
    right: 0;
    position: absolute;
    transform: translateX(40%) translateY(-150%);
    transition: all 0.2s ease-in-out;
  }
  .check-wrapper.chosen {
    transform: translateX(-1em) translateX(50%) translateY(0%);
  }
  .check {
    background-color: var(--action-fg);
    padding: 0.5em;
    transform: rotate(45deg);
    width: 100px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .check-inner {
    transform: rotate(-45deg);
  }
</style>
