import { assertDefined, newTypedArray, newTypedObject } from "../util/index.ts"

const DEFAULT_MAX_CACHE_SIZE = 4096

export class MemCache<K extends symbol | number | string, V> {
  private cache: Partial<Record<K, V>>
  private keyQueue: K[]
  private maxCacheSize: number

  constructor(maxCacheSize = DEFAULT_MAX_CACHE_SIZE) {
    if (maxCacheSize < 1) {
      throw "cache size must be at least one"
    }
    this.cache = newTypedObject<K, V>()
    this.keyQueue = newTypedArray<K>()
    this.maxCacheSize = maxCacheSize
  }

  public has(key: K) {
    return key in this.cache
  }

  public get(key: K) {
    return this.cache[key]
  }

  public getWithDefaultSync(key: K, onMiss?: () => V) {
    if (!this.has(key) && onMiss) {
      this.add(key, onMiss())
    }
    return assertDefined(this.cache[key])
  }

  public add(key: K, value: V) {
    if (!this.has(key)) {
      this.keyQueue.push(key)
      if (this.keyQueue.length > this.maxCacheSize) {
        const keyToDelete = assertDefined(this.keyQueue.pop())
        delete this.cache[keyToDelete]
      }
    }

    this.cache[key] = value
  }

  public delete(key: K) {
    for (const [i, k] of this.keyQueue.entries()) {
      if (k === key) {
        this.keyQueue = this.keyQueue.splice(i, 1)
        break
      }
    }
    delete this.cache[key]
  }
}
