import { quintOut } from "svelte/easing"
import { fade, slide } from "svelte/transition"
import type { SlideParams } from "svelte/transition"

export function slideAndFade(
  node: HTMLElement,
  { delay = 0, duration = 400, easing = quintOut, axis = "y" }: SlideParams
): ReturnType<typeof slide> {
  const slideTransition = slide(node, { delay, duration, easing, axis })
  const fadeTransition = fade(node, { delay, duration, easing })

  return {
    delay,
    duration,
    easing,
    css: (t: number, u: number) => {
      const slideCSS = slideTransition.css!(t, u)
      const fadeCSS = fadeTransition.css!(t, u)
      return `${slideCSS}; ${fadeCSS}`
    },
    tick: (t: number, u: number) => {
      slideTransition.tick?.(t, u)
      fadeTransition.tick?.(t, u)
    },
  }
}
