<script lang="ts">
  import Button from "@/lib/Button.svelte"
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"
  import { postCheckAuth } from "@/stores/authStore"
  import { getItem, LocalStorageKey } from "@/util"
  import { onMount } from "svelte"
  import { globalAuthCallbackModal } from "@/lib/modals"
  import { apiClient } from "@/api"
  import type { ApiResponseFromKeyT } from "@shared/api"

  export let query: URLSearchParams | null

  const REDIRECT_THRESHOLD_MILLISECONDS = 120 * 1000
  enum PageState {
    init = "init",
    error = "error",
    redirecting = "redirecting",
  }

  let errorMessage: string | null = null
  let pageState: PageState = PageState.init
  let activityData: ApiResponseFromKeyT<"checkLocalUser">["activity"] | null =
    null
  let newlyCreatedValue: boolean = false

  const error = (message: string) => {
    pageState = PageState.error
    console.error(message)
    errorMessage = message
  }

  const redirect = () => {
    pageState = PageState.redirecting
    const target = getItem(LocalStorageKey.authRedirectUrl)
    const now = new Date().getTime()
    if (!target || now - target.timestamp > REDIRECT_THRESHOLD_MILLISECONDS) {
      window.location.replace("/")
    } else {
      window.location.replace(target.href)
    }
  }

  const linkData = async () => {
    await apiClient.call("mergeLocalUser", null)
    redirect()
  }

  const authenticate = async (code: string) => {
    try {
      const { success, newlyCreated } = await apiClient.call(
        "completeBrowserAuth",
        {
          code,
        }
      )
      newlyCreatedValue = newlyCreated
      if (!success) {
        error("Authentication failed")
        return
      }
    } catch (e) {
      console.error(e)
      error("Authentication failed")
      return
    }

    try {
      const resp = await apiClient.call("getOrAddUser", null)
      if (!resp.userType) {
        throw "unexpected unauthenticated user"
      }
      postCheckAuth(resp)

      const { activity } = await apiClient.call("checkLocalUser", null)

      if (activity.length === 0) {
        redirect()
      } else {
        activityData = activity
        globalAuthCallbackModal.open({
          props: { newlyCreatedValue, activityData },
          onAccept: (accepted: boolean) => (accepted ? linkData() : redirect()),
        })
      }
    } catch (e) {
      console.warn(e)
      redirect()
    }
  }

  onMount(async () => {
    const code = query?.get("code")
    if (!code) {
      error("Authentication failed")
      return
    }
    await authenticate(code)
  })
</script>

<main>
  <section>
    {#if pageState == PageState.redirecting}
      You will be redirected shortly
    {:else if pageState == PageState.error}
      {errorMessage}
      <RouterLink to="/"><Button>Back to Home</Button></RouterLink>
    {/if}
  </section>
</main>

<style>
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
  }
</style>
