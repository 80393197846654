<script lang="ts">
  import { sheetContentFactory } from "@/stores/sheetStore"
  import { coalesceEmptyArray, newTypedArray } from "@shared/util/index.ts"
  import { fieldInfoMap } from "@shared/data/defaults.ts"
  import Checkbox from "@/lib/Checkbox.svelte"
  import type { EnumT } from "@shared/schema/index.ts"
  import SidepanelContent from "./SidepanelContent.svelte"
  import { getSocket } from "@/client/socket"

  type HideableColumn = { hidden?: boolean; name: string } & (
    | {
        type: "column"
        columnId: string
        field?: EnumT.Field
      }
    | {
        type: "field"
        field: EnumT.Field
      }
  )

  export let sheetId: string

  $: sheetContent = $sheetContentFactory(sheetId)

  const toggleVisible = async ({ column }: { column: HideableColumn }) => {
    if (column.type === "column") {
      ;(await getSocket()).emit("setColumnVisible", {
        visible: !!column.hidden,
        columnId: column.columnId,
        sheetId,
      })
    } else {
      ;(await getSocket()).emit("addFieldColumn", {
        field: column.field,
        sheetId,
      })
    }
  }

  $: availableFieldRefNames = Array.from(
    new Set(sheetContent?.fields.map((field) => field.refName))
  )
  $: usedFieldRefNames = new Set(
    sheetContent?.columnDefinitions
      .filter((colDef) =>
        sheetContent?.columns.some(
          (col) => col.columnDefinitionId === colDef.id
        )
      )
      .map((colDef) => colDef.field)
  )
  $: unusedFieldRefNames = [...availableFieldRefNames].filter(
    (refName) => !usedFieldRefNames.has(refName)
  )

  $: displayedColumns = newTypedArray<HideableColumn>([
    ...coalesceEmptyArray(
      sheetContent?.columns
        .sort((colA, colB) => colA.sortedOrder - colB.sortedOrder)
        .map(
          (col) =>
            ({
              type: "column",
              columnId: col.id,
              hidden: col.hidden,
              field: sheetContent?.columnDefinitions.find(
                (colDef) => colDef.id === col.columnDefinitionId
              )?.field,
              name: col.name,
            } as HideableColumn)
        )
    ),
    ...unusedFieldRefNames.map(
      (field) =>
        ({
          type: "field",
          hidden: true,
          field,
          name: fieldInfoMap[field].name,
        } as HideableColumn)
    ),
  ])
</script>

<SidepanelContent>
  <div class="content">
    {#each displayedColumns as displayedColumn}
      <div class="activity">
        <div class="activity-item-inner">
          <span
            ><Checkbox
              checked={!displayedColumn.hidden}
              onChange={() => toggleVisible({ column: displayedColumn })}
              label={displayedColumn.name}
            /></span
          >
        </div>
      </div>
    {/each}
  </div>
</SidepanelContent>

<style>
  @media only screen and (min-width: 770px) {
    .content {
      min-width: 350px;
    }
  }
</style>
