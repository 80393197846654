<script lang="ts">
  import { createEventDispatcher } from "svelte"
  import ModalFooter from "./ModalFooter.svelte"
  import CopyText from "../controls/CopyText.svelte"

  export let value: string = ""

  const dispatch = createEventDispatcher<{ close: undefined }>()

  const onClose = () => dispatch("close")
</script>

<CopyText {value} />
<ModalFooter
  on:close={onClose}
  onAccept={onClose}
  acceptText="Close"
  hideCancelButton
/>
