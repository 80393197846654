<script lang="ts">
  import type { Variant } from "@/types"
  import { createEventDispatcher } from "svelte"
  import Button from "../Button.svelte"

  export let hideCancelButton: boolean = false
  export let mode: "yesno" | "default" | "cancelonly" = "default"
  export let acceptDisabled: boolean = false
  export let acceptVariant: Variant = "default"
  export let acceptText: string = "Accept"
  export let yesText: string = "Yes"
  export let noText: string = "No"
  export let cancelText: string = "Cancel"
  export let onAccept: (() => void) | undefined = undefined
  export let onYes: (() => void) | undefined = undefined
  export let onNo: (() => void) | undefined = undefined

  const dispatch = createEventDispatcher<{ close: undefined }>()

  const close = () => dispatch("close")
</script>

<footer>
  <span>
    {#if !hideCancelButton}
      <Button on:click={close}>{cancelText}</Button>
    {/if}</span
  >
  {#if mode === "default"}
    <Button
      disabled={acceptDisabled}
      variant={acceptVariant}
      on:click={onAccept}>{acceptText}</Button
    >
  {:else if mode === "yesno"}
    <span>
      <Button disabled={acceptDisabled} variant="danger" on:click={onNo}
        >{noText}</Button
      >
      <Button disabled={acceptDisabled} variant="success" on:click={onYes}
        >{yesText}</Button
      >
    </span>
  {/if}
</footer>

<style>
  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
  }
</style>
