<script lang="ts">
  import type { OptionT } from "@/types"
  import { builtins } from "@shared/lispable/functions.ts"
  import { objectEntries } from "@shared/util/index.ts"
  import { createEventDispatcher } from "svelte"
  import SingleSelect from "../SingleSelect.svelte"

  export let value: string | undefined

  const dispatch = createEventDispatcher<{
    change: string
  }>()
  const functionOptions: OptionT<string>[] = objectEntries(builtins).map(
    ([funcName, funcDef]) => ({ label: funcDef.name, value: funcName })
  )

  const changeFunc = (newName: string) => dispatch("change", newName)
</script>

<SingleSelect
  placeholder="Select a calculation"
  onChange={changeFunc}
  {value}
  options={functionOptions}
/>
