import RouterRoot from "./RouterRoot.svelte"
import { curPath } from "./store.ts"

export default RouterRoot

export const navigateTo = (path: string, replace = false) => {
  if (path.startsWith("http")) {
    window.open(path, "_blank", "noopener")
    return
  }
  if (replace) {
    window.history.replaceState(null, "", path)
  } else {
    window.history.pushState(null, "", path)
  }
  curPath.set(path)
}
