import type { ApiResponseFromKeyT } from "@shared/api"
import { EnumT } from "@shared/schema/index.ts"
import { derived, writable } from "svelte/store"

export const didInitialLoad = writable<boolean>(false)
export const userType = writable<EnumT.UserType | undefined>(undefined)
export const isAnon = writable<boolean>(true)
export const numSheets = writable<number | undefined>()
export const signedIn = derived([userType, isAnon], ([$userType, $isAnon]) => {
  return $userType && !$isAnon
})
export const isInternalUser = derived(
  [userType],
  ([$userType]) => $userType === EnumT.UserType.admin || import.meta.env.DEV
)

export const didUnauthenticate = () => {
  didInitialLoad.set(true)
  userType.set(undefined)
  isAnon.set(true)
  numSheets.set(undefined)
}

export const postCheckAuth = (args: ApiResponseFromKeyT<"getOrAddUser">) => {
  didInitialLoad.set(true)
  if (args.userType) {
    userType.set(args.userType)
    isAnon.set(args.anon)
    numSheets.set(args.numSheets)
  } else {
    didUnauthenticate()
  }
}
