<script lang="ts">
  import type { TableCellMetadata } from "./DataTable/types"
  import { activeSheetId, hasWriteAccess } from "@/stores/sheetStore"
  import { assertDefined } from "@shared/util"
  import Button from "./Button.svelte"
  import type { GenericDataValue } from "@shared/types"
  import { getSocket } from "@/client/socket"

  export let metadata: TableCellMetadata
  export let showPopover: boolean | undefined = undefined

  $: originalData = ((): GenericDataValue | null => {
    if (!metadata.cellValue?.userInputData) {
      return null
    }

    if (metadata.cellValue.calculatedData) {
      return metadata.cellValue.calculatedData
    }

    return metadata.cellValue.extractedData ?? null
  })()

  $: hasWrite = hasWriteAccess({ sheetId: $activeSheetId ?? undefined })

  const restore = async () => {
    ;(await getSocket()).emit("clearCell", {
      ...metadata.cellKey,
      sheetId: assertDefined($activeSheetId),
    })
    showPopover = false
  }
</script>

<div class="content">
  {#if originalData}
    Original Value:
    <code>{originalData}</code>
    <Button disabled={!$hasWrite} on:click={restore} size="small"
      >Restore</Button
    >
  {/if}
</div>

<style>
</style>
