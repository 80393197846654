<script lang="ts">
  import Sidepanel from "./Sidepanel.svelte"
  import type { SidepanelManagerT } from "./index.ts"

  export let sidepanelManager: SidepanelManagerT

  $: sidepanels = sidepanelManager.sidepanels

  const close = (id: string) => () => sidepanelManager.closeSidepanel(id)
</script>

{#each $sidepanels as sidepanel}
  <Sidepanel
    side={sidepanel.side}
    on:close={close(sidepanel.id)}
    title={sidepanel.title}
  >
    <svelte:component this={sidepanel.component} {...sidepanel.props} />
  </Sidepanel>
{/each}
