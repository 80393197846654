<script lang="ts">
  import {
    buildGeneratorSpecGraph,
    containsAsyncFunctionCall,
  } from "@shared/lispable"
  import { ParsedItemType, type ParsedItem } from "@shared/lispable/parsing"
  import { cachedParseScript } from "@shared/lispable/script"
  import type {
    FunctionArgument,
    GenericDataValue,
    ProcessedSheetContent,
  } from "@shared/types.ts"
  import { assertDefined, topologicalSort } from "@shared/util/index.ts"
  import type { OptionT } from "@/types"
  import SingleSelect from "../SingleSelect.svelte"
  import { ReferenceNamespace } from "@shared/schema/enum"
  import Button from "../Button.svelte"
  import { faAdd } from "@fortawesome/free-solid-svg-icons"
  import { EnumT } from "@shared/schema/index.ts"
  import type { OpenAddVariableModal } from "./types.ts"
  import DataFormatEditor from "../DataFormat/display/DataFormatEditor.svelte"

  export let funcArg: FunctionArgument
  export let sheetContent: ProcessedSheetContent
  export let columnId: string | undefined
  export let value: ParsedItem | undefined
  export let onChange: ((newValue: ParsedItem) => void) | undefined = undefined
  export let openAddVariableModal: OpenAddVariableModal

  const setToColumn = (colId: string) => {
    onChange?.({
      type: ParsedItemType.reference,
      namespace: ReferenceNamespace.columns,
      identifier: colId,
    })
  }

  const setToVariable = (sheetGlobalId: string) => {
    onChange?.({
      type: ParsedItemType.reference,
      namespace: ReferenceNamespace.globals,
      identifier: sheetGlobalId,
    })
  }

  const setStaticValue = (val: GenericDataValue | undefined) => {
    if (typeof val === "string" && funcArg.dataType === EnumT.DataType.text) {
      onChange?.({
        type: ParsedItemType.strLiteral,
        value: val,
      })
    } else if (
      typeof val === "number" &&
      funcArg.dataType === EnumT.DataType.number
    ) {
      onChange?.({
        type: ParsedItemType.numLiteral,
        value: val,
      })
    } else if (
      typeof val === "boolean" &&
      funcArg.dataType === EnumT.DataType.boolean
    ) {
      onChange?.({
        type: ParsedItemType.boolLiteral,
        value: val,
      })
    } else if (val === undefined) {
      onChange?.({ type: ParsedItemType.nullLiteral })
    } else {
      throw `bad combination: ${JSON.stringify(val)} ${funcArg.dataType}`
    }
  }

  const filterMatchingColDef = ({
    columnDefinitionId,
  }: {
    columnDefinitionId: string
  }) => {
    const colDef = assertDefined(
      sheetContent.columnDefinitions.find((cd) => cd.id === columnDefinitionId)
    )

    if (
      funcArg.formatSpec &&
      colDef.formatSpec.type !== funcArg.formatSpec.type
    ) {
      return false
    }
    if (colDef.dataType !== funcArg.dataType) {
      return false
    }

    return true
  }

  const doOpenAddVariableModal = () =>
    openAddVariableModal(
      {
        sheetId: sheetContent.sheet.id,
        forceDataType: funcArg.dataType,
        forceFormatSpec: funcArg.formatSpec,
      },
      setToVariable
    )

  $: variableOptions = sheetContent.sheetGlobals
    .filter(filterMatchingColDef)
    .map((variable) => ({
      label: variable.varName,
      value: variable.id,
    })) as OptionT<string>[]

  $: columnOptions = sheetContent.columns
    .filter(filterMatchingColDef)
    .filter((col) => {
      const colDef = assertDefined(
        sheetContent.columnDefinitions.find(
          (cd) => cd.id === col.columnDefinitionId
        )
      )
      // Filter out any columns that would cause a cycle, as well as any columns
      // that have generatorSpecs that contain async function calls. (Only
      // relevant for editing existing columns, we can skip this check if
      // columnId is undefined).
      if (colDef.generatorSpec && columnId) {
        const { nodes, edges } = buildGeneratorSpecGraph({
          columns: sheetContent.columns,
          columnDefinitions: sheetContent.columnDefinitions,
        })
        // Check for async function calls
        const script = cachedParseScript(colDef.generatorSpec)
        if (!containsAsyncFunctionCall(script)) {
          return false
        }

        // Check if we would be making a cycle
        const edgesAmended = { ...edges }
        edgesAmended[columnId].push({ to: col.id })
        const nodesAmended = {
          [columnId]: { type: ParsedItemType.symbol, symbol: "dummy" },
          ...nodes,
        }
        try {
          topologicalSort(nodesAmended, edgesAmended)
        } catch {
          // Cycle detected
          return false
        }
      }
      return true
    })
    .map((column) => ({
      label: column.name,
      value: column.id,
    })) as OptionT<string>[]

  $: selectedColumn = (() => {
    if (
      value?.type === ParsedItemType.reference &&
      value.namespace === ReferenceNamespace.columns
    ) {
      return value.identifier
    }
    return undefined
  })()

  $: selectedVariable = (() => {
    if (
      value?.type === ParsedItemType.reference &&
      value.namespace === ReferenceNamespace.globals
    ) {
      return value.identifier
    }
    return undefined
  })()

  $: selectedStaticValue = (() => {
    if (
      value?.type === ParsedItemType.strLiteral ||
      value?.type === ParsedItemType.numLiteral ||
      value?.type === ParsedItemType.boolLiteral
    ) {
      return value.value
    }
    return undefined
  })()

  $: selectedStaticValueForFixedList = selectedStaticValue as string | number
</script>

{#if funcArg.fixedList}
  {funcArg.label}
  <SingleSelect
    onChange={setStaticValue}
    options={funcArg.fixedList}
    value={selectedStaticValueForFixedList}
    placeholder={funcArg.label}
    clearable={funcArg.nullable}
  />
{:else if funcArg.variableName}
  {funcArg.variableName}
  <DataFormatEditor
    value={selectedStaticValue}
    dataType={funcArg.dataType}
    formatSpec={assertDefined(funcArg.formatSpec)}
    onChange={setStaticValue}
    clearable={funcArg.nullable}
  />
{:else}
  <div class="wrapper">
    <SingleSelect
      onChange={setToColumn}
      options={columnOptions}
      value={selectedColumn}
      placeholder="Use a column"
    />
    <div class="or-separator">or</div>
    <div class="select-variable">
      <SingleSelect
        fullwidth
        onChange={setToVariable}
        options={variableOptions}
        value={selectedVariable}
        placeholder="Use a variable"
      />
      <div class="new-variable-wrapper">
        <Button iconLeft={faAdd} on:click={doOpenAddVariableModal}
          >New Variable</Button
        >
      </div>
    </div>
  </div>
{/if}

<style>
  .select-variable {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .new-variable-wrapper {
    margin-left: 10px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .or-separator {
    margin-top: 10px;
    align-self: center;
  }
</style>
