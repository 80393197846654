<script lang="ts">
  import { navigateTo } from "."

  export let to: string
  export let nofocus: boolean = false
  export let cssClass: string = ""

  // from https://github.com/vuejs/router/blob/4a0cc8b9c1e642cdf47cc007fa5bbebde70afc66/packages/router/src/RouterLink.ts#L293
  function guardEvent(e: MouseEvent) {
    // don't redirect with control keys
    if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) return
    // don't redirect when preventDefault called
    if (e.defaultPrevented) return
    // don't redirect on right click
    if (e.button !== undefined && e.button !== 0) return
    // don't redirect if `target="_blank"`
    // @ts-expect-error getAttribute does exist
    if (e.currentTarget && e.currentTarget.getAttribute) {
      // @ts-expect-error getAttribute exists
      const target = e.currentTarget.getAttribute("target")
      if (/\b_blank\b/i.test(target)) return
    }
    // this may be a Weex event which doesn't have this method
    if (e.preventDefault) e.preventDefault()

    return true
  }

  const navigate = (e: MouseEvent) => {
    if (guardEvent(e)) {
      navigateTo(to)
    }
  }
</script>

<a href={to} class={cssClass} tabindex={nofocus ? -1 : 0} on:click={navigate}
  ><slot /></a
>
