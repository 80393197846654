<script lang="ts">
  import { debounce, useMapView } from "@/util"
  import { getPreferredStyle } from "@/util/map"
  import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
  import { Location } from "@shared/util/location"
  import { Map } from "maplibre-gl"
  import { onMount, tick } from "svelte"
  import Icon from "../Icon.svelte"
  import LocationInput from "../LocationInput.svelte"
  import type { AcceptCallback } from "../modalManager/types.ts"
  import ModalFooter from "./ModalFooter.svelte"

  export let initialValue: Location | undefined = undefined
  export let onAccept: AcceptCallback<Location> | undefined
  export let readonly: boolean = false

  let mapElem: HTMLDivElement | undefined
  let map: Map | null = null
  let value: Location | undefined = initialValue

  const acceptModal = () => {
    const center = map!.getCenter()
    onAccept?.(new Location({ lat: center.lat, lon: center.lng }))
  }

  const initializeMap = async () => {
    await tick()
    if (!mapElem) {
      return
    }
    map = new Map({
      container: mapElem,
      center: initialValue,
      zoom: 13,
      style: getPreferredStyle(),
      customAttribution: "",
      cooperativeGestures: false,
      interactive: false,
    })
  }

  $: if (value?.nonZero()) {
    map?.panTo(value.toObj())
  }

  onMount(() => {
    if (mapElem) {
      new ResizeObserver(debounce(initializeMap, 300)).observe(mapElem)
      // initializeMap()
    }
    useMapView()
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        if (event.matches) {
          map?.setStyle(getPreferredStyle(event))
        }
      })
  })

  $: acceptDisabled = !map
</script>

{#if !readonly}
  <div class="input-wrapper">
    <LocationInput bind:value />
  </div>
{/if}
<div class="wrapper">
  <div class="container" bind:this={mapElem} />
  <div class="marker"><Icon icon={faLocationDot} /></div>
</div>
<ModalFooter
  mode={readonly ? "cancelonly" : "default"}
  cancelText={readonly ? "Close" : "Cancel"}
  on:close
  {acceptDisabled}
  onAccept={acceptModal}
/>

<style>
  .wrapper {
    position: relative;
    border-radius: var(--default-rounding);
    overflow: hidden;
  }
  .wrapper,
  .container {
    width: 100%;
    height: min(80vw, 400px);
  }
  .marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: 40px;
    color: var(--action-alt);
  }
  .input-wrapper {
    display: flex;
    padding-bottom: 8px;
  }
</style>
