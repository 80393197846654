<script lang="ts">
  import { classNames } from "@/util"
  import type { Side } from "./types.ts"

  export let axis: "y" | "x" | undefined = undefined
  export let sides: Side[] = ["left", "right", "top", "bottom"]

  $: computedSides = (() => {
    if (axis === "y") {
      return ["top", "bottom"]
    } else if (axis === "x") {
      return ["left", "right"]
    } else {
      return sides
    }
  })()
</script>

<div class={classNames(...computedSides)}><slot /></div>

<style>
  :root {
    --margin-width: 8px;
  }
  .left {
    margin-left: var(--margin-width);
  }
  .right {
    margin-right: var(--margin-width);
  }
  .top {
    margin-top: var(--margin-width);
  }
  .bottom {
    margin-bottom: var(--margin-width);
  }
</style>
