<script lang="ts">
  import { listingTypeInfoMap } from "@shared/data/defaults"
  import type { EnumT } from "@shared/schema"
  import { objectEntries } from "@shared/util"
  import Choosable from "../controls/Choosable.svelte"
  import ListingTypeCard from "./ChooseListingTypeModal/ListingTypeCard.svelte"
  import FixedLayoutVertical from "../layout/FixedLayoutVertical.svelte"

  export let onAccept: (listingType: EnumT.ListingType | null) => void
  let selectedListingType: EnumT.ListingType | undefined | null = undefined

  const selectListingType = (listingType: EnumT.ListingType | null) => () => {
    selectedListingType = listingType
    onAccept(selectedListingType)
  }
  const unselectListingType = () => (selectedListingType = undefined)

  $: listingCardData = objectEntries(listingTypeInfoMap).map(
    ([listingType, info]) => ({ listingType, info })
  )
</script>

<FixedLayoutVertical>
  <div class="content">
    <div class="listing-type-cards">
      {#each listingCardData as listingInfo}
        {@const listingType = listingInfo.listingType}
        <Choosable
          chosen={selectedListingType === listingType}
          anyChosen={selectedListingType !== undefined}
          ariaDescription={listingInfo.info.name.plural}
          groupName="listingType"
          clearable
          on:unchoose={unselectListingType}
          on:choose={selectListingType(listingType)}
        >
          <ListingTypeCard {listingType} />
        </Choosable>
      {/each}
      <Choosable
        chosen={selectedListingType === null}
        anyChosen={selectedListingType !== undefined}
        ariaDescription="Other"
        groupName="listingType"
        clearable
        on:unchoose={unselectListingType}
        on:choose={selectListingType(null)}
      >
        <ListingTypeCard listingType={null} />
      </Choosable>
    </div>
  </div>
</FixedLayoutVertical>

<style>
  .listing-type-cards {
    display: grid;
    gap: 1em;
    grid-template-columns: auto;
    user-select: none;
    padding: 1em;
  }
  @media only screen and (min-width: 770px) {
    .listing-type-cards {
      grid-template-columns: auto auto;
    }
  }
</style>
