<script lang="ts">
  import { EnumT } from "@shared/schema/index.ts"
  import { CurrencyCode } from "@shared/schema/currencies"
  import type { FormatSpec } from "@shared/types.ts"
  import { objectValues } from "@shared/util/index.ts"
  import Checkbox from "./Checkbox.svelte"
  import SingleSelect from "./SingleSelect.svelte"

  export let formatSpecType: EnumT.FormatSpecType
  export let formatSpec: FormatSpec | undefined = undefined

  let includeTime: boolean = false
  let currencyCode: CurrencyCode | undefined = (() => {
    if (formatSpec?.type === EnumT.FormatSpecType.currency) {
      return formatSpec.currencyCode
    }
  })()
  let minimumUnits: EnumT.DurationUnits | undefined = (() => {
    if (formatSpec?.type === EnumT.FormatSpecType.duration) {
      return formatSpec.minimumUnits
    }
  })()

  const updateLocals = (fs: FormatSpec) => {
    switch (fs.type) {
      case EnumT.FormatSpecType.currency:
        currencyCode = fs.currencyCode
        break
      case EnumT.FormatSpecType.duration:
        minimumUnits = fs.minimumUnits
        break
    }
  }

  $: if (formatSpec) updateLocals(formatSpec)

  const currencyOptions = objectValues(CurrencyCode).map((val) => ({
    label: val,
    value: val,
  }))
  const durationUnitsOptions = objectValues(EnumT.DurationUnits).map(
    (value) => ({
      label: value,
      value,
    })
  )

  $: {
    if (formatSpecType === EnumT.FormatSpecType.datetime) {
      formatSpec = {
        type: EnumT.FormatSpecType.datetime,
        includeTime,
      }
    } else if (
      formatSpecType === EnumT.FormatSpecType.currency &&
      currencyCode
    ) {
      formatSpec = {
        type: EnumT.FormatSpecType.currency,
        currencyCode,
      }
    } else if (formatSpecType === EnumT.FormatSpecType.geographicCoordinates) {
      formatSpec = {
        type: EnumT.FormatSpecType.geographicCoordinates,
      }
    } else if (formatSpecType === EnumT.FormatSpecType.duration) {
      formatSpec = {
        type: EnumT.FormatSpecType.duration,
        minimumUnits,
      }
    } else {
      formatSpec = formatSpec
    }
  }
</script>

{#if formatSpecType === EnumT.FormatSpecType.datetime}
  <div class="checkbox-wrapper">
    <Checkbox label="Include Time" bind:checked={includeTime} />
  </div>
{:else if formatSpecType === EnumT.FormatSpecType.currency}
  Currency
  <SingleSelect bind:value={currencyCode} options={currencyOptions} />
{:else if formatSpecType === EnumT.FormatSpecType.duration}
  Units
  <SingleSelect
    bind:value={minimumUnits}
    options={durationUnitsOptions}
    clearable
  />
{/if}

<style>
  .checkbox-wrapper {
    margin-top: 5px;
    display: inline-bblock;
  }
</style>
