import type { Route } from "./types.ts"

export const curPathStr = () => window.location.pathname

export const pickRoute = (routes: Route[]) => {
  let matches: RegExpExecArray | null = null
  const curPath = curPathStr()
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i]
    matches = route.pattern.exec(curPath)
    const url = new URL(window.location.href)
    if (!!matches && matches.length > 0) {
      return {
        matches,
        component: route.component,
        dynamicComponent: route.dynamicComponent,
        hash: url.hash.slice(1),
        query: url.searchParams,
        route,
      }
    }
  }
  return null
}
