<script lang="ts">
  import { isMobile } from "@/util"
  import { COMMON_ANIMATION_OPTIONS } from "@/util/animation"
  import {
    faArrowRight,
    faChevronDown,
    faDollarSign,
    faPhone,
    faShieldHalved,
    faTable,
  } from "@fortawesome/free-solid-svg-icons"
  import { onMount } from "svelte"
  import Icon from "@/lib/Icon.svelte"
  import SmoothScroll from "smooth-scroll"

  import { animate } from "motion"
  import SourceInfoPanel from "./SourceInfoPanel.svelte"
  import TextMessages from "./TextMessages.svelte"
  import DemoTable from "./DemoTable.svelte"
  import FooterPanel from "./FooterPanel.svelte"
  import MainEnterButton from "./MainEnterButton.svelte"
  import { sheetRedirectRoute } from "@/routes"
  import { faLightbulb } from "@fortawesome/free-regular-svg-icons"

  onMount(() => {
    animate(
      ".scroll-hint",
      { y: [0, 50, 0] },
      {
        duration: 4,
        offset: [0, 0.35, 1],
        repeat: Infinity,
        ...COMMON_ANIMATION_OPTIONS,
      }
    )
  })

  const demoSheetLink = import.meta.env.VITE_APP_DEMO_SHEET_ID
    ? sheetRedirectRoute({ sheetId: import.meta.env.VITE_APP_DEMO_SHEET_ID })
    : undefined

  const smoothScrollToEnd = () =>
    isMobile()
      ? new SmoothScroll().animateScroll(
          document.querySelector(".section-0-right")
        )
      : new SmoothScroll().animateScroll(document.querySelector(".demo-table"))
</script>

<main>
  <div class="section-0">
    <div class="section-0-left">
      <div class="section-0-left-inner">
        <span class="listable">Listable</span>
        <div class="subtitle">
          Automatically organize your apartment search into a spreadsheet. Just
          paste a link!
        </div>
        <div class="create-sheet-wrapper">
          <MainEnterButton />
        </div>
        <!--<div class="scroll-to-learn">Scroll to learn more</div>-->
        <div
          class="scroll-hint"
          tabindex="0"
          role="button"
          on:click={smoothScrollToEnd}
        >
          <Icon icon={faChevronDown} />
        </div>
      </div>
    </div>
    <div class="section-0-right">
      <SourceInfoPanel />
      <TextMessages />
    </div>
  </div>
  <div class="spacer" />
  <div class="section-0-5" />
  <div class="section-1"><DemoTable /></div>
  <div class="section-0-5" />
  <div class="section-2">
    <div class="section-2-inner">
      <div class="footer-grid">
        <FooterPanel
          variant="action-alt"
          actionText="View demo"
          actionIconRight={faArrowRight}
          icon={faTable}
          title="Demo"
          to={demoSheetLink}
          >See an example of how to use Listable to search for apartments.</FooterPanel
        >
        <FooterPanel
          actionText="Drop us a line"
          actionIconLeft={faPhone}
          emoji="☕"
          title="Let's Chat"
          to="sms://+18606317105"
          >In NYC? Let us buy you a drink and talk about how Listable can serve
          you better. You can also send us a text at <a
            href="sms://+18606317105">(860) 631-7105</a
          >.</FooterPanel
        >
        <FooterPanel
          variant="action"
          actionText="Learn more"
          actionIconRight={faArrowRight}
          icon={faLightbulb}
          title="Anti Deceptive Pattern Statement"
          >Listable is serious about earning your trust on a web filled with
          <a href="https://www.deceptive.design">deceptive patterns</a>. Read
          about the measures we take to be good stewards of our corner of the
          internet.</FooterPanel
        >
        <FooterPanel
          actionText="Learn more"
          variant="success"
          actionIconRight={faArrowRight}
          icon={faDollarSign}
          title="Premium"
          >Listable is free for most use cases, and also offers optional premium
          features. Learn more about our upfront pricing.</FooterPanel
        >
      </div>
    </div>
    <div class="footer-wrapper">
      <div class="made-with-love">
        Made with <img src="/heart.svg" class="heart" /> in NYC
      </div>
      <div class="footer">
        <div class="footer-links"><span>Privacy</span></div>
      </div>
    </div>
  </div>
  <div class="dummy" />
</main>

<style>
  .spacer {
    width: 1px;
    height: 1px;
  }
  .scroll-hint {
    width: 50px;
    text-align: center;
    display: block;
    font-size: 60px;
    text-align: center;
    z-index: 15;
    cursor: pointer;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--primary-fg);
    margin-left: auto;
    margin-right: auto;
    margin-top: -75px;
  }
  .dummy {
    opacity: 0;
  }
  .section-0 {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .section-0-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    z-index: 2;
  }
  .section-0-left-inner {
    text-align: center;
  }
  .section-0-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    z-index: 2;
  }
  .section-2 {
    min-height: 100vh;
    background: #0aa3ba;
  }
  .section-1 {
    height: 300vh;
  }
  .section-0-5 {
    height: 20vh;
  }
  .listable {
    margin-top: 20px;
    font-family: "Sarabun", sans-serif;
    font-size: 120px;
    line-height: 120px;
    text-transform: uppercase;
    display: block;
  }
  .subtitle {
    margin-top: 75px;
    font-size: 20px;
    max-width: calc(min(500px, 90vw));
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: 769px) {
    .listable {
      font-size: 80px;
    }
  }
  .create-sheet-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
  }
  .footer-grid {
    margin-top: 100px;
    display: grid;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
    gap: 25px 50px;
  }
  @media only screen and (min-width: 1200px) {
    .section-0-left-inner {
      position: sticky;
      top: 0;
    }
    .section-0 {
      display: flex;
      flex-direction: row;
      max-width: 100vw;
    }
    .listable {
      margin-top: 200px;
    }
    .footer-grid {
      grid-template-columns: auto auto;
      width: 1000px;
    }
  }
  .section-2 {
    display: flex;
    flex-direction: column;
  }
  .section-2-inner {
    flex: 1;
  }
  .footer {
    background-color: var(--secondary-bg);
    font-weight: 600;
    padding-top: 20px;
    font-size: 18px;
    padding-bottom: 20px;
    text-align: center;
    font-family: "Sarabun", sans-serif;
  }
  .made-with-love {
    font-size: 20px;
    color: var(--primary-bg);
    font-weight: 800;
    text-align: center;
    margin-top: 50px;
  }
  .heart {
    aspect-ratio: 1 / 1;
    width: 25px;
    vertical-align: middle;
  }
  .footer-links {
    display: grid;
    grid-template-columns: auto;
    column-gap: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    text-align: center;
  }
  main {
    overflow-x: clip;
    overflow-y: visible;
  }
  :global(.create-sheet-wrapper:hover .create-sheet-sticker-common) {
    opacity: 1;
    transform: translateX(50%) translateY(-50%) scale(0.85);
  }
</style>
