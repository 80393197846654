<script lang="ts">
  import ImagePreviewButton from "@/lib/ImagePreviewButton.svelte"
  import { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import {
    DataFormatOverrideType,
    type DataFormatOverrides,
    type DataFormatEvent,
    DataFormatEventTypes,
  } from "./types"

  export let textOnly: boolean = false
  export let value: GenericDataValue<EnumT.DataType.textArray> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}

  let imagePreviewButton: ImagePreviewButton | undefined = undefined

  export const emitEvent = (event: DataFormatEvent) => {
    if (event.type === DataFormatEventTypes.primaryAction) {
      imagePreviewButton?.openImagePreviewModal()
    }
  }

  $: nofocus = dataFormatOverrides[DataFormatOverrideType.nofocus]

  $: displayedValue = ((): string => {
    if (!value) {
      return ""
    }
    switch (formatSpec.type) {
      case EnumT.FormatSpecType.images:
      case EnumT.FormatSpecType.none:
        return value.join(", ")
      default:
        return `ERR: unsupported format type: ${formatSpec.type}`
    }
  })()
</script>

{#if value}
  {#if textOnly}
    {displayedValue}
  {:else if formatSpec.type === EnumT.FormatSpecType.images}
    <ImagePreviewButton
      stopPropagation={dataFormatOverrides[
        DataFormatOverrideType.stopPropagation
      ]}
      bind:this={imagePreviewButton}
      images={value.map((im) => ({ src: im }))}
      {nofocus}
    />
  {:else}
    {displayedValue}
  {/if}
{/if}
