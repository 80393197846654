<script lang="ts">
  import SourceCard from "@/lib/SourceCard.svelte"
  import { COMMON_ANIMATION_OPTIONS } from "@/util/animation"
  import { listingTypeInfoMap, sourceInfoMap } from "@shared/data/defaults"
  import type { EnumT } from "@shared/schema/index.ts"
  import type { ElementOf } from "@shared/types.ts"
  import {
    coalesceEmptyArray,
    objectEntries,
    randIntInRange,
  } from "@shared/util/index.ts"
  import { animate } from "motion"
  import { onDestroy, onMount } from "svelte"

  const INTERVAL_MILLISECONDS = 6000
  const DURATION_SECONDS = 0.75
  const sourceTypeStringsMap: { source: EnumT.Source; example: string }[] =
    objectEntries(sourceInfoMap)
      .filter(([_, info]) => info.listingTypes && info.listingTypes.length > 0)
      .flatMap(([source, info]) =>
        coalesceEmptyArray(
          info.listingTypes?.flatMap((listingType) =>
            listingTypeInfoMap[listingType].examples.map((example) => ({
              source,
              example,
            }))
          )
        )
      )

  let activeSource: EnumT.Source = sourceTypeStringsMap[0].source
  let activeTypeString: string = sourceTypeStringsMap[0].example
  let infoPanelInterval: ReturnType<typeof setInterval>

  const animateSourceTransition = (newSource: EnumT.Source) => {
    animate(
      ".source-info-card",
      { opacity: [1, 0, 1], scale: [1, 0.5, 1] },
      { duration: DURATION_SECONDS, ...COMMON_ANIMATION_OPTIONS }
    )
    animate(
      (t: number) => {
        if (t > 0.5 && activeSource !== newSource) {
          activeSource = newSource
        }
      },
      { duration: DURATION_SECONDS, ...COMMON_ANIMATION_OPTIONS }
    )
  }

  const animateTypeStringTransition = (newTypeString: string) => {
    const duration = 0.75
    const phase1Duration = duration / 3
    const phase2Duration = duration - phase1Duration
    const selector = `.type-string`
    const typeStringElem = document.querySelector(selector) as HTMLDivElement
    animate(
      selector,
      { y: [0, -100] },
      { duration: phase1Duration, ...COMMON_ANIMATION_OPTIONS }
    )
    setTimeout(() => {
      typeStringElem.innerText = newTypeString
      activeTypeString = newTypeString
      animate(
        selector,
        { y: 100 },
        { duration: 0, ...COMMON_ANIMATION_OPTIONS }
      )
      animate(
        selector,
        { y: [100, 0] },
        { duration: phase2Duration, ...COMMON_ANIMATION_OPTIONS }
      )
    }, phase1Duration * 1000)
  }

  const animateSourceInfo = () => {
    let newSourceInfo: ElementOf<typeof sourceTypeStringsMap> | undefined =
      undefined
    while (
      newSourceInfo === undefined ||
      activeSource === newSourceInfo?.source
    ) {
      const index = randIntInRange(0, sourceTypeStringsMap.length - 1)
      newSourceInfo = sourceTypeStringsMap[index]
    }
    animateSourceTransition(newSourceInfo.source)
    animateTypeStringTransition(newSourceInfo.example)
  }

  const onClickInfoPanel = () => {
    animateSourceInfo()
    clearInterval(infoPanelInterval)
    infoPanelInterval = setInterval(animateSourceInfo, INTERVAL_MILLISECONDS)
  }

  onMount(() => {
    ;(document.querySelector(".type-string") as HTMLDivElement).innerText =
      activeTypeString
    infoPanelInterval = setInterval(animateSourceInfo, INTERVAL_MILLISECONDS)
  })

  onDestroy(() => {
    clearInterval(infoPanelInterval)
  })
</script>

<div
  class="info-panel source-info-box"
  role="button"
  aria-label="scroll text"
  on:click={onClickInfoPanel}
>
  <div class="info-panel-inner">
    Find the best

    <div class="inline type-string-wrapper">
      <div class="type-string" />
    </div>
    <br />
    from
    <div class="inline source-info-card-wrapper">
      &nbsp;
      <div class="source-info-card source-info-card-desktop">
        <SourceCard size="large" readonly nofocus source={activeSource} />
      </div>
      <div class="source-info-card source-info-card-mobile">
        <SourceCard readonly source={activeSource} />
      </div>
    </div>
  </div>
</div>

<style>
  .source-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 180%;
    margin-left: 10px;
    margin-right: 10px;
    width: 70vw;
    cursor: pointer;
    height: 100px;
    margin-top: 100px;
  }
  .info-panel {
    background: var(--secondary-bg);
    border-radius: var(--default-rounding);
    box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.45);
    padding: 20px;
    padding-left: 35px;
    padding-right: 35px;
    font-size: 20px;
  }
  .type-string-wrapper {
    width: 120px;
    position: relative;
    overflow: hidden;
    vertical-align: bottom;
    height: 30px;
  }
  .type-string {
    font-weight: 700;
    position: absolute;
    bottom: 0;
  }
  .inline {
    display: inline-block;
  }
  .source-info-card-wrapper {
    position: relative;
  }
  .source-info-card {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .source-info-card-desktop {
    display: none;
  }
  .source-info-card-mobile {
    display: unset;
  }
  .info-panel-inner {
    display: pre;
    white-space: normal;
    user-select: none;
  }
  @media only screen and (min-width: 770px) {
    .source-info-box {
      margin-top: 200px;
    }
    .source-info-card-mobile {
      display: none;
    }
    .source-info-card-desktop {
      display: unset;
    }
    .info-panel {
      width: 400px;
      font-size: 30px;
    }
    .type-string-wrapper {
      width: 200px;
      height: 50px;
    }
  }
</style>
