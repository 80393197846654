<div class="fixed-relative-anchor">
  <slot />
</div>

<style>
  .fixed-relative-anchor {
    position: relative;
    flex: 1;
    height: 100%;
  }
</style>
