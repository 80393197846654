<script lang="ts">
  import { getSocket } from "@/client/socket"
  import Editable from "@/lib/Editable.svelte"
  import { hasWriteAccess } from "@/stores/sheetStore"
  import { EnumT } from "@shared/schema/index.ts"
  import { getColumnForField, getParsedValueByField } from "@shared/sheet"
  import type { ProcessedSheetContent } from "@shared/types.ts"

  export let rowId: string
  export let sheetContent: ProcessedSheetContent

  $: hasWrite = hasWriteAccess({ sheetId: sheetContent.sheet.id })

  $: titleColumnId = getColumnForField({
    sheetContent,
    field: EnumT.Field.title,
  })?.id

  $: title = getParsedValueByField<EnumT.Field.title>({
    sheetContent,
    rowId,
    field: EnumT.Field.title,
  })

  const onChange = async (ev: CustomEvent<string>) => {
    if (titleColumnId) {
      ;(await getSocket()).emit("editCell", {
        rowId,
        colId: titleColumnId,
        data: ev.detail,
        sheetId: sheetContent.sheet.id,
      })
    }
  }
</script>

<Editable
  readonly={!$hasWrite}
  value={title ?? undefined}
  on:enter={onChange}
/>
