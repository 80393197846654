<script lang="ts" context="module">
  const getGoodDealText = ({
    fieldValueLookup,
  }: {
    fieldValueLookup: PartialFieldValueLookup
  }) => {
    const priceUsd = fieldValueLookup[EnumT.Field.priceUsd]
    const estimatedPriceUsd = fieldValueLookup[EnumT.Field.predictedPriceUsd]

    if (priceUsd == null || estimatedPriceUsd == null) {
      return undefined
    }
    const diff = Math.abs(estimatedPriceUsd - priceUsd)
    const formattedDiff = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: "USD",
      trailingZeroDisplay: "stripIfInteger",
    } as Intl.NumberFormatOptions).format(diff)
    if (priceUsd < estimatedPriceUsd) {
      return { text: `${formattedDiff} below market`, good: true }
    }
    if (priceUsd > estimatedPriceUsd) {
      return { text: `${formattedDiff} above market`, good: false }
    }
  }

  export type PageOption = "table" | "list" | "map" | "details"
</script>

<script lang="ts">
  import type {
    PartialFieldValueLookup,
    ProcessedSheetContent,
  } from "@shared/types.ts"
  import { getColumnIdForField } from "@/util"
  import type { Undefined } from "@/global"

  import type { LatLonTuple } from "@shared/util/location"
  import { EnumT } from "@shared/schema/index.ts"
  import { itemPageRoute, listRoute, mapRoute, tableRoute } from "@/routes"
  import {
    faList,
    faLocationCrosshairs,
    faTable,
    faThumbsDown,
    faThumbsUp,
  } from "@fortawesome/free-solid-svg-icons"
  import Button from "./Button.svelte"
  import SourceCard from "./SourceCard.svelte"
  import type { Size } from "@/types"
  import RatingStars from "./RatingStars.svelte"

  export let size: Size = "default"
  export let pageOptions: PageOption[] = []
  export let showRating: boolean = false
  export let fieldValueLookup: PartialFieldValueLookup
  export let sheetContent: ProcessedSheetContent | undefined
  export let rowId: string | undefined

  $: location = fieldValueLookup[EnumT.Field.location] as Undefined<LatLonTuple>

  $: locationColumnId = sheetContent
    ? getColumnIdForField({
        field: EnumT.Field.location,
        sheetContent,
      })
    : undefined

  $: url = fieldValueLookup[EnumT.Field.url]

  $: rating = fieldValueLookup[EnumT.Field.rating]

  $: source = fieldValueLookup[EnumT.Field.source] as Undefined<EnumT.Source>

  $: goodDealInfo = getGoodDealText({ fieldValueLookup })
</script>

<div class="fact-row">
  {#if source && url}
    <a
      on:click|stopPropagation
      tabindex="-1"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <SourceCard {size} islink {source} />
    </a>
  {/if}
  {#if pageOptions.includes("table") && sheetContent}
    <Button
      iconLeft={faTable}
      nofocus
      to={tableRoute({ sheetId: sheetContent.sheet.id, itemId: rowId })}
      {size}>View in Table</Button
    >
  {/if}
  {#if pageOptions.includes("list") && sheetContent}
    <Button
      iconLeft={faList}
      nofocus
      to={listRoute({ sheetId: sheetContent.sheet.id, itemId: rowId })}
      {size}>View in List</Button
    >
  {/if}
  {#if location && locationColumnId && pageOptions.includes("map") && sheetContent && rowId}
    <Button
      iconLeft={faLocationCrosshairs}
      nofocus
      to={mapRoute({
        sheetId: sheetContent.sheet.id,
        itemId: `${rowId}`,
        selectedColumn: `${locationColumnId}`,
      })}
      {size}>View on Map</Button
    >
  {/if}
  {#if pageOptions.includes("details") && sheetContent && rowId}
    <Button
      nofocus
      to={itemPageRoute({ sheetId: sheetContent.sheet.id, itemId: rowId })}
      {size}>View Details</Button
    >
  {/if}
  {#if goodDealInfo}
    <Button
      variant={goodDealInfo.good ? "success" : "danger"}
      iconLeft={goodDealInfo.good ? faThumbsUp : faThumbsDown}
      readonly
      {size}>{goodDealInfo.text}</Button
    >
  {/if}
  {#if rating != null && showRating}
    <RatingStars readonly value={rating} />
  {/if}
</div>

<style>
  .fact-row {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .success-blurb {
    color: var(--success-fg);
    background-color: var(--success-bg);
  }
  .danger-blurb {
    color: var(--danger-fg);
    background-color: var(--danger-bg);
  }
  .success-blurb,
  .danger-blurb {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
  }
</style>
