<script lang="ts">
  import {
    columnOptions,
    defaultFormatSpec,
    UserDataType,
    userDataTypeDataMap,
    userDataTypeFormatMap,
  } from "@/util/columns"
  import type { EnumT } from "@shared/schema/index.ts"
  import type { FormatSpec } from "@shared/types.ts"
  import { coalesceEmptyArray } from "@shared/util/index.ts"
  import type { AcceptCallback } from "../modalManager/types.ts"
  import FormatSpecEditor from "../FormatSpecEditor.svelte"
  import Input from "../Input.svelte"
  import ModalFooter from "./ModalFooter.svelte"
  import SingleSelect from "../SingleSelect.svelte"
  import { getSocket } from "@/client/socket.ts"

  export let active: boolean = false
  export let sheetId: string
  export let forceDataType: EnumT.DataType | undefined = undefined
  export let forceFormatSpec: FormatSpec | undefined = undefined
  export let onAccept: AcceptCallback<string> | undefined = undefined

  let varName: string = ""
  let userDataType: UserDataType | undefined = undefined
  let formatSpecType: EnumT.FormatSpecType | undefined = forceFormatSpec?.type
  let formatSpec: FormatSpec | undefined = forceFormatSpec

  $: dataType = forceDataType
    ? forceDataType
    : userDataType
    ? userDataTypeDataMap[userDataType]
    : undefined
  $: formatSpecTypeOptions = userDataType
    ? userDataTypeFormatMap[userDataType]
    : undefined
  $: acceptDisabled = !(dataType && varName)

  const addVariable = async () => {
    if (!dataType) {
      return
    }
    const resolvedFormatSpec = {
      ...defaultFormatSpec(userDataType),
      ...formatSpec,
    }

    ;(await getSocket()).emit(
      "addSheetGlobal",
      {
        sheetId,
        varName,
        formatSpec: resolvedFormatSpec,
        dataType,
      },
      (id) => {
        onAccept?.(id)
      }
    )

    active = false
  }
</script>

<div class="content">
  <Input type="text" bind:value={varName} placeholder="Variable name" />
  {#if !forceDataType}
    <SingleSelect
      label="Variable Type"
      options={columnOptions}
      bind:value={userDataType}
      placeholder="Select variable type"
      clearable
    />
  {/if}
  {#if coalesceEmptyArray(formatSpecTypeOptions).length > 0 && !forceFormatSpec}
    <SingleSelect
      label="Format Type"
      options={coalesceEmptyArray(formatSpecTypeOptions)}
      bind:value={formatSpecType}
      placeholder="Select a format type (optional)"
      clearable
    />
  {/if}
  {#if formatSpecType && !forceFormatSpec}
    <FormatSpecEditor {formatSpecType} bind:formatSpec />
  {/if}
</div>
<ModalFooter on:close {acceptDisabled} onAccept={addVariable} />
