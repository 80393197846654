<script lang="ts">
  export let component: ConstructorOfATypedSvelteComponent | null = null
  export let tag: string | null = null
  export let wrap = false
</script>

{#if wrap}
  {#if component}
    <svelte:component this={component} {...$$restProps}>
      <slot />
    </svelte:component>
  {:else}
    <svelte:element this={tag} on:click {...$$restProps}>
      <slot />
    </svelte:element>
  {/if}
{:else}
  <slot />
{/if}
