<script lang="ts">
  import InternalUi from "@/lib/InternalUI.svelte"
  import { navigateTo } from "@/lib/RouterView"

  import RouterRoot from "@/lib/RouterView/RouterRoot.svelte"
  import type { Route } from "@/lib/RouterView/types"
  import { homeRoute } from "@/routes"
  import { didInitialLoad, isInternalUser } from "@/stores/authStore"
  import Playground from "../Playground.svelte"
  import ProxyConfigPage from "../ProxyConfigPage.svelte"
  import { isDev } from "@/util"

  const routes: Route[] = [
    {
      pattern: /\/playground/,
      component: Playground,
      dynamicComponent: undefined,
    },
    {
      pattern: /\/proxy_config/,
      component: ProxyConfigPage,
      dynamicComponent: undefined,
    },
  ]

  $: {
    if ($didInitialLoad && !($isInternalUser || isDev())) {
      navigateTo(homeRoute())
    }
  }
</script>

<InternalUi>
  <RouterRoot {routes} />
</InternalUi>
