<script lang="ts">
  import { navigateTo } from "@/lib/RouterView"
  import { listRoute, tableRoute, type SheetRouteBase } from "@/routes"

  import { isMobile } from "@/util"

  export let query: URLSearchParams | null
  export let matches: [string, string]

  $: {
    const sheetId = matches[1]
    const itemId = query?.get("itemId") ?? undefined

    const sheetRouteProps: SheetRouteBase = {
      sheetId,
      itemId,
    }

    if (isMobile()) {
      navigateTo(listRoute(sheetRouteProps), true)
    } else {
      navigateTo(tableRoute(sheetRouteProps), true)
    }
  }
</script>
