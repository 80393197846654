<script lang="ts">
  import { faEye } from "@fortawesome/free-solid-svg-icons"
  import { createEventDispatcher } from "svelte"
  import Icon from "./Icon.svelte"
  import type { ImageInfo } from "./ImagePreviewCarousel.svelte"
  import { globalImagePreviewModal } from "./modals/index.ts"

  export let images: ImageInfo[]
  export let stopPropagation: boolean = false
  export let nofocus: boolean = false

  const dispatch = createEventDispatcher<{ close: undefined }>()

  $: firstImage = images[0]

  export const openImagePreviewModal = (e?: Event) => {
    globalImagePreviewModal.open({
      props: { images },
      onClose: () => dispatch("close"),
    })
    if (stopPropagation) {
      e?.stopPropagation()
      e?.stopImmediatePropagation()
      e?.preventDefault()
      return false
    }
  }
</script>

{#if firstImage}
  <div class="first-image-wrapper">
    <img
      class="square"
      alt={firstImage.caption ?? "thumbnail"}
      src={firstImage.src}
    /><button
      tabindex={nofocus ? -1 : 0}
      on:click={openImagePreviewModal}
      class="extras-placeholder"
      ><span class="extras-text"><Icon icon={faEye} /></span></button
    >
  </div>{/if}

<style>
  .first-image-wrapper {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }
  .square,
  .first-image-wrapper {
    width: 4em;
    height: 4em;
  }
  .extras-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-fg);
    color: var(--primary-bg);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    border: none;
    padding: 0;
    outline: inherit;
    width: 100%;
  }
  .extras-placeholder:hover {
    opacity: 0.8;
  }
  .extras-text {
    font-size: 22px;
    font-weight: bold;
  }
</style>
