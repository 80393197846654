<script lang="ts">
  import Icon from "../Icon.svelte"
  import type { ToastMessageExtended, Position } from "./types.ts"
  import { fade, fly } from "svelte/transition"
  import { classNames } from "@/util"

  export let position: Position
  export let toasts: ToastMessageExtended[]
</script>

<div class={classNames(position, "wrapper")}>
  {#each toasts as tm (tm.id)}
    <div
      in:fly={{ y: 100, duration: 250 }}
      out:fade={{ duration: 250 }}
      class={`toast-message ${tm.variant ?? "default"}`}
    >
      {#if tm.icon}
        <Icon icon={tm.icon} />
      {/if}
      {tm.message}
    </div>
  {/each}
</div>

<style>
  .bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  .top-center {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .toast-message {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;
    margin: 10px;
    width: 300px;
  }
  .wrapper {
    z-index: 100000;
  }
  .toast-message.default {
    background-color: var(--primary-accent);
  }
  .toast-message.danger {
    background-color: var(--primary-danger);
  }
  .toast-message.success {
    background-color: var(--primary-success);
  }
</style>
