<script lang="ts">
  import { timeAgo } from "@/util"
  import { faFileLines } from "@fortawesome/free-solid-svg-icons"
  import Icon from "../Icon.svelte"
  import ModalFooter from "./ModalFooter.svelte"
  import type { ApiResponseFromKeyT } from "@shared/api"

  export let activityData:
    | ApiResponseFromKeyT<"checkLocalUser">["activity"]
    | null = null
  export let newlyCreatedValue: boolean = false
  export let onAccept: (accepted: boolean) => void

  const onYes = () => onAccept(true)
  const onNo = () => onAccept(false)
</script>

<p>
  {#if newlyCreatedValue}
    Before creating an account, you had activity on the following sheets:
  {:else}
    Before signing in, you had activity on the following sheets:
  {/if}
</p>

<div class="activity-data">
  {#if activityData}
    {#each activityData as activity}
      <div class="activity">
        {#if activity.entityType === "sheet"}
          <div class="activity-item-inner">
            <span>
              <Icon icon={faFileLines} />
              <span>{activity.name ? activity.name : "Untitled"}</span>
            </span>
            <span>
              {timeAgo(activity.timestamp)}
            </span>
          </div>
        {/if}
      </div>
    {/each}
  {/if}
</div>

<p>
  {#if newlyCreatedValue}
    Would you like to link this activity to your account or start fresh?
  {:else}
    Would you like to link this activity to your account or discard it?
  {/if}
</p>

<ModalFooter
  hideCancelButton
  mode="yesno"
  yesText="Link Data"
  noText={newlyCreatedValue ? "Start Fresh" : "Discard Data"}
  {onYes}
  {onNo}
/>
