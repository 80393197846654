<script lang="ts">
  import Button from "@/lib/Button.svelte"
  import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons"
  import { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import { DataFormatOverrideType, type DataFormatOverrides } from "./types"
  import { globalEditLocationModal } from "@/lib/modals"
  import { Location, assertDefined } from "@shared/util"
  import type { LatLonTuple } from "@shared/util/location"
  import { navigateTo } from "@/lib/RouterView"

  export let textOnly: boolean = false
  export let value: GenericDataValue<EnumT.DataType.numberArray> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides
  export const emitEvent = () => {
    if (formatSpec.type === EnumT.FormatSpecType.geographicCoordinates) {
      viewLocation()
    }
  }

  $: viewLocationLink =
    dataFormatOverrides[DataFormatOverrideType.viewLocationLink]

  $: displayedValue = ((): string => {
    if (!value) {
      return ""
    }
    switch (formatSpec.type) {
      case EnumT.FormatSpecType.none:
        return value.join(", ")
      case EnumT.FormatSpecType.geographicCoordinates:
        return `Latitude: ${value[0]}\nLongitude: ${value[1]}`
      default:
        return `ERR: unsupported format type: ${formatSpec.type}`
    }
  })()

  const viewLocation = () => {
    if (!viewLocationLink) {
      globalEditLocationModal.open({
        props: {
          initialValue: new Location(assertDefined(value) as LatLonTuple),
          readonly: true,
        },
        onAccept: () => {},
      })
    } else {
      navigateTo(viewLocationLink)
    }
  }
</script>

{#if value}
  {#if textOnly}
    {displayedValue}
  {:else if formatSpec.type === EnumT.FormatSpecType.geographicCoordinates}
    <Button
      iconLeft={faLocationCrosshairs}
      nofocus
      to={viewLocationLink}
      on:click={viewLocation}
      size="small">View on Map</Button
    >
  {:else}
    {displayedValue}
  {/if}
{/if}
