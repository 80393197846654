<script lang="ts">
  import { dashboardRoute, listRoute, tableRoute } from "@/routes"
  import { navigateTo } from "@/lib/RouterView"
  import { isMobile } from "@/util"
  import { didInitialLoad, numSheets } from "@/stores/authStore"
  import RouterLink from "@/lib/RouterView/RouterLink.svelte"
  import { fade } from "svelte/transition"
  import type { EnumT } from "@shared/schema"
  import { defaultSheetNameForListingType } from "@shared/data/defaults"
  import { globalChooseListingTypeModal } from "@/lib/modals"
  import { apiClient } from "@/api"

  const createSheet = () => {
    globalChooseListingTypeModal.open({
      props: {},
      onAccept: async (listingType: EnumT.ListingType | null) => {
        const { sheetId } = await apiClient.call("createSheet", {
          listingType,
          name: listingType
            ? defaultSheetNameForListingType(listingType)
            : undefined,
        })
        const to = isMobile() ? listRoute({ sheetId }) : tableRoute({ sheetId })
        navigateTo(to)
      },
    })
  }

  const duration = 500
</script>

{#if $didInitialLoad}
  {#if !$numSheets}
    <button transition:fade|global={{ duration }} on:click={createSheet}
      >Start a list</button
    >
    <div class="create-sheet-sticker-common create-sheet-sticker-outer" />
    <div class="create-sheet-sticker-common create-sheet-sticker-inner">
      <div class="create-sheet-sticker-inner-label">
        no login<br />needed!
      </div>
    </div>
  {:else}
    <RouterLink to={dashboardRoute()}>
      <button transition:fade|global={{ duration }}>Go to Lists</button>
    </RouterLink>
  {/if}
{:else}
  <!--hack to avoid layout jumping when fading in the button-->
  <button class="invisible">Text</button>
{/if}

<style>
  button {
    background: var(--primary-bg);
    outline: none;
    border: none;
    color: var(--primary-fg);
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 24px;
    padding-bottom: 24px;
    font-family: "Sarabun", sans-serif;
    text-transform: uppercase;
    display: block;
    font-size: 22px;
    border: 5px solid var(--primary-fg);
  }
  .create-sheet-sticker-common {
    --inner-sticker-width: 100px;
    opacity: 0;
    aspect-ratio: 1 / 1;
    position: absolute;
    top: -10px;
    right: -10px;
    transform: translateX(50%) translateY(-50%);
    clip-path: polygon(
      100% 50%,
      86.71% 59.84%,
      93.3% 75%,
      76.87% 76.87%,
      75% 93.3%,
      59.84% 86.71%,
      50% 100%,
      40.16% 86.71%,
      25% 93.3%,
      23.13% 76.87%,
      6.7% 75%,
      13.29% 59.84%,
      0% 50%,
      13.29% 40.16%,
      6.7% 25%,
      23.13% 23.13%,
      25% 6.7%,
      40.16% 13.29%,
      50% 0%,
      59.84% 13.29%,
      75% 6.7%,
      76.87% 23.13%,
      93.3% 25%,
      86.71% 40.16%
    );
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }
  .create-sheet-sticker-inner {
    width: var(--inner-sticker-width);
    background-color: var(--primary-bg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .create-sheet-sticker-outer {
    width: calc(var(--inner-sticker-width) + 16px);
    background-color: var(--primary-fg);
  }
  .create-sheet-sticker-inner-label {
    transform: rotate(15deg);
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-fg);
  }
  .create-sheet-sticker-common:hover {
    opacity: 1;
  }
  .invisible {
    opacity: 0;
  }
</style>
