<script lang="ts">
  import type { AcceptCallback } from "../modalManager/types.ts"
  import ModalFooter from "./ModalFooter.svelte"

  export let onAccept: AcceptCallback<boolean>

  export let onYes = () => onAccept(true)
  export let onNo = () => onAccept(false)
</script>

<slot />
<ModalFooter on:close onAccept={onYes} {onYes} {onNo} {...$$restProps} />
