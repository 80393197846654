<script lang="ts">
  import ImagePreviewCarousel from "@/lib/ImagePreviewCarousel.svelte"
  import { EnumT } from "@shared/schema/index.ts"
  import { getParsedValueByField } from "@shared/sheet"
  import type { ProcessedSheetContent } from "@shared/types.ts"
  import { coalesceEmptyArray } from "@shared/util/index.ts"

  export let rowId: string
  export let sheetContent: ProcessedSheetContent

  $: photos = getParsedValueByField<EnumT.Field.images>({
    rowId,
    sheetContent,
    field: EnumT.Field.images,
  })

  $: images = coalesceEmptyArray(photos?.map((src) => ({ src })))
</script>

{#if images.length > 0}
  <ImagePreviewCarousel {images} />
{/if}
