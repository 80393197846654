<script lang="ts">
  import { useTrapFocus } from "@/util"
  import { createEventDispatcher, onDestroy, onMount } from "svelte"

  let elem: HTMLDivElement | undefined
  let releaseFocus: (() => void) | undefined

  const dispatch = createEventDispatcher<{ close: undefined }>()
  const onEscape = () => dispatch("close")

  const trapFocus = () => {
    if (!elem) {
      return
    }
    releaseFocus = useTrapFocus({
      elem,
      onEscape,
    }).release
  }

  onMount(trapFocus)

  onDestroy(() => {
    releaseFocus?.()
  })
</script>

<div bind:this={elem} class="wrapper">
  <slot />
</div>

<style>
  .wrapper {
    display: contents;
  }
</style>
