<script lang="ts" context="module">
  const computeIcon = (types: string[]) => {
    for (const type of types) {
      switch (type) {
        case "airport":
          return faPlane
        case "aquarium":
          return faFish
        case "art_gallery":
          return faPaintbrush
        case "bakery":
          return faBreadSlice
        case "bank":
          return faPiggyBank
        case "bar":
          return faMartiniGlassCitrus
        case "book-store":
          return faBook
        case "bus_station":
          return faBus
        case "cafe":
          return faMugHot
        case "casino":
          return faDice
        case "courthouse":
          return faGavel
        case "church":
          return faChurch
        case "dentist":
          return faTooth
        case "doctor":
          return faStethoscope
        case "fire_station":
          return faFireExtinguisher
        case "gym":
          return faDumbbell
        case "hospital":
          return faHospital
        case "synagogue":
          return faSynagogue
        case "library":
          return faBook
        case "mosque":
          return faMosque
        case "movie_theater":
          return faFilm
        case "restaurant":
          return faUtensils
        case "school":
          return faSchool
        case "subway_station":
          return faSubway
        case "university":
          return faSchool
      }
    }
    return faLocationPin
  }
</script>

<script lang="ts">
  import {
    faBook,
    faBreadSlice,
    faBus,
    faChurch,
    faDice,
    faDumbbell,
    faFilm,
    faFireExtinguisher,
    faFish,
    faGavel,
    faHospital,
    faLocationPin,
    faMartiniGlassCitrus,
    faMosque,
    faMugHot,
    faPaintbrush,
    faPiggyBank,
    faPlane,
    faSchool,
    faStethoscope,
    faSubway,
    faSynagogue,
    faTooth,
    faUtensils,
  } from "@fortawesome/free-solid-svg-icons"
  import Icon from "./Icon.svelte"

  export let value: { placeId: string; types: string[]; label: string }

  $: icon = computeIcon(value.types)
</script>

<span>
  <span class="icon"><Icon {icon} /></span>&nbsp;{value.label}
</span>

<style>
  .icon {
    filter: brightness(0.8);
  }
</style>
