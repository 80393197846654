<script lang="ts">
  import type {
    PartialFieldValueLookup,
    ProcessedSheetContent,
  } from "@shared/types.ts"
  import { flashElem, timeAgo } from "@/util"

  import { EnumT } from "@shared/schema/index.ts"
  import { coalesceEmptyArray } from "@shared/util/index.ts"
  import ImagePreviewButton from "./ImagePreviewButton.svelte"
  import QuickFacts from "./QuickFacts.svelte"
  import { faCircle } from "@fortawesome/free-solid-svg-icons"
  import { DateTime } from "luxon"
  import Icon from "./Icon.svelte"
  import ItemTagLine, { type PageOption } from "./ItemTagLine.svelte"

  type Columns = $$Generic<
    Partial<Record<string | symbol | number, TableColumn<any>>>
  >

  export let allDetails: boolean = false
  export let pageOptions: PageOption[] = []
  export let fieldValueLookup: PartialFieldValueLookup
  export let sheetContent: ProcessedSheetContent | undefined
  export let rowId: string | undefined

  let wrapperElem: HTMLDivElement | undefined

  export const flash = () => {
    if (wrapperElem) {
      flashElem(wrapperElem)
      wrapperElem.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  $: title = fieldValueLookup[EnumT.Field.title]

  $: photos = fieldValueLookup[EnumT.Field.images]
  $: images = coalesceEmptyArray(photos?.map((src) => ({ src })))
  $: dateListed = fieldValueLookup[EnumT.Field.dateListed]
  $: formattedDateListed = dateListed ? `Listed ${timeAgo(dateListed)}` : null

  $: dateAvailable = fieldValueLookup[EnumT.Field.availableAt]
  $: formattedDateAvailable = dateAvailable
    ? `Available ${DateTime.fromSeconds(dateAvailable).toLocaleString()}`
    : null

  $: infoBlurbs = [formattedDateListed, formattedDateAvailable].filter(
    (item) => !!item
  ) as string[]
</script>

<div class="content pl pr pt pb" bind:this={wrapperElem}>
  <div class="top-row">
    <div class="pr image-wrapper">
      {#if photos}
        <ImagePreviewButton stopPropagation {images} />
      {/if}
    </div>
    <div class="title-wrapper">
      <span class="title">
        <slot name="title">
          {title}
        </slot>
      </span>
      {#if allDetails}
        <ItemTagLine
          size="small"
          {pageOptions}
          {fieldValueLookup}
          showRating
          {sheetContent}
          {rowId}
        />
      {/if}
    </div>
  </div>
  <div class="quick-fact-section">
    <div class="fact-row">
      <QuickFacts {fieldValueLookup} />
    </div>
    {#if allDetails}
      {#each infoBlurbs as infoBlurb}
        <span class="info-blurb"
          ><Icon icon={faCircle} /><span class="info-blurb-text"
            >{infoBlurb}</span
          ></span
        >
      {/each}
    {/if}
  </div>
</div>

<style>
  .info-blurb-text {
    margin-left: 8px;
  }
  .info-blurb {
    display: flex;
    align-items: center;
  }
  .quick-fact-section {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
  .fact-row {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .top-row {
    display: flex;
    align-items: center;
  }
  .image-wrapper {
    float: left;
  }
  .content::after {
    content: "";
    clear: both;
    display: table;
  }
  .pl {
    padding-left: 10px;
  }
  .pr {
    padding-right: 10px;
  }
  .pt {
    padding-top: 10px;
  }
  .pb {
    padding-bottom: 10px;
  }
  .title-wrapper {
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "...";
  }
  .title {
    font-size: large;
    font-weight: 600;
    color: var(--primary-fg);
  }
  .content {
    transition: background-color 0.25s ease-in-out;
  }
  :global(.content.flash) {
    background-color: var(--action-alt);
  }
</style>
