import type { ComponentProps, ComponentType, SvelteComponent } from "svelte"
import { readonly, writable } from "svelte/store"
import type { AcceptCallback, MadeModal, ModalProps } from "./types.ts"

export const createModalManager = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store = writable<MadeModal<any, any>[]>([])

  const closeModal = (modalId: string) => {
    store.update((existing) => {
      const index = existing.findIndex((modal) => modal.id === modalId)
      if (index < 0) {
        return existing
      }
      const updated = [...existing]
      const modal = updated.splice(index, 1)
      modal[0]?.preservedFocus?.focus?.()
      modal[0]?.onClose?.()
      return updated
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const makeModal = <
    T,
    PT extends T extends never ? object : { onAccept?: AcceptCallback<T> }
  >(
    makeModalArgs: {
      component: ComponentType<SvelteComponent<PT>>
      title: string
      description: string
    } & ModalProps
  ) => {
    const modalId = crypto.randomUUID()

    return {
      open: ({
        props,
        onAccept,
        onClose,
      }: {
        props: Omit<ComponentProps<SvelteComponent<PT>>, "onAccept">
        onAccept?: AcceptCallback<T>
        onClose?: () => void
      }) => {
        const preservedFocus = document.activeElement
        store.update((existing) => [
          ...existing,
          {
            ...makeModalArgs,
            props,
            id: modalId,
            onClose,
            preservedFocus,
            onAccept: (a: T) => {
              closeModal(modalId)
              onAccept?.(a)
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as MadeModal<any, any>,
        ])
      },
      close: () => closeModal(modalId),
    }
  }

  const modals = readonly(store)

  return {
    makeModal,
    closeModal,
    modals,
  }
}

export type ModalManagerT = ReturnType<typeof createModalManager>
