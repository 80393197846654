import { logException } from "@/util"
import { apiClient } from "../api"
import type {
  ServerToClientEvents,
  ClientToServerEvents,
} from "@shared/api/ws.ts"
import { memoize } from "@shared/util"
import { io, type Socket } from "socket.io-client"
import { writable } from "svelte/store"

export const connected = writable<boolean>(false)

const socket = memoize(
  () =>
    new Promise<Socket<ServerToClientEvents, ClientToServerEvents>>(
      (resolve) => {
        apiClient
          .call("getOrAddUser", null)
          .then(() => {
            const base = import.meta.env.VITE_APP_SOCKET_URL as
              | string
              | undefined
            const socketIO: Socket<ServerToClientEvents, ClientToServerEvents> =
              base
                ? io(base, {
                    transports: ["websocket"],
                  })
                : io("", {
                    transports: ["websocket"],
                  })
            socketIO.on("connect", () => connected.set(true))
            socketIO.on("disconnect", () => connected.set(false))
            resolve(socketIO)
          })
          .catch(logException)
      }
    )
)

export function getSocket() {
  return socket()
}
