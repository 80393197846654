<script lang="ts" generics="T extends CellDataProviderBase">
  import type { CellKey } from "@shared/types.ts"

  import { ArrowKey, ArrowKeys, EscapeKeys } from "@/constants"
  import { classNames } from "@/util"

  import { SvelteComponent, tick } from "svelte"

  import TableRow from "./TableRow.svelte"
  import DataTableTd from "./DataTableTd.svelte"
  import CondWrapper from "../CondWrapper.svelte"
  import type {
    CellDataProviderBase,
    CellDataProviderIncluded,
  } from "./types.ts"

  type PT<CT> = CellDataProviderIncluded & Omit<CT, keyof CellDataProviderBase>
  type ET = {
    requestEdit: CustomEvent<undefined | Event>
    requestStopEdit: CustomEvent<undefined>
    requestFocus: CustomEvent<undefined>
  }

  export let stickyHeader: boolean | undefined = undefined
  export let className: string | undefined = undefined
  export let transpose: boolean = false
  export let striped: boolean = false
  export let layoutFixed: boolean = false
  export let fullwidth: boolean = false
  export let readonly: boolean = false
  export let cellRenderer: typeof SvelteComponent<PT<T>, ET>
  export let cellDataProvider: (cellKey: CellKey) => T
  export let columns: string[]
  export let rows: string[]

  let tableElem: HTMLTableElement
  let activeColId: string | null = null
  let activeRowId: string | null = null
  let editing: boolean = false

  const rowComponents: Partial<Record<string, { flashRow: () => void }>> = {}

  const requestEdit = (rowId: string, colId: string) => {
    if (readonly) {
      return
    }
    activeRowId = rowId
    activeColId = colId
    editing = true
  }

  const requestStopEdit = () => {
    editing = false
  }

  const focusedCell = async (rowId: string, colId: string) => {
    activeColId = null
    activeRowId = null
    await tick()
    activeColId = colId
    activeRowId = rowId
  }

  const focusOut = (e: FocusEvent) => {
    const focusedElem = e.relatedTarget as HTMLElement | null
    if (!focusedElem || !tableElem.contains(focusedElem)) {
      editing = false
    } else {
      const parentTd = focusedElem.closest("td")
      const selectedRowId = parentTd?.getAttribute("data-row-id")
      const selectedColId = parentTd?.getAttribute("data-col-id")
      if (selectedRowId !== activeRowId || selectedColId !== activeColId) {
        // TODO: is this unneeded?
        // editing = false
      }
    }
  }

  const checkArrows = (e: KeyboardEvent) => {
    const leftKey = transpose ? ArrowKey.up : ArrowKey.left
    const rightKey = transpose ? ArrowKey.down : ArrowKey.right
    const upKey = transpose ? ArrowKey.left : ArrowKey.up
    const downKey = transpose ? ArrowKey.right : ArrowKey.down

    const curRow = activeRowId ?? rows[0]
    const curCol = activeColId ?? columns[0]
    if (e.key === rightKey) {
      const curColIndex = columns.findIndex((id) => id === curCol)
      const focusedColIndex = Math.min(curColIndex + 1, columns.length - 1)
      activeColId = columns[focusedColIndex]
      activeRowId = curRow
    } else if (e.key === downKey) {
      const curRowIndex = rows.findIndex((id) => id === curRow)
      const focusedRowIndex = Math.min(curRowIndex + 1, rows.length - 1)
      activeColId = curCol
      activeRowId = rows[focusedRowIndex]
    } else if (e.key === leftKey) {
      const curColIndex = columns.findIndex((id) => id === curCol)
      const focusedColIndex = Math.max(curColIndex - 1, 0)
      activeColId = columns[focusedColIndex]
      activeRowId = curRow
    } else if (e.key === upKey) {
      const curRowIndex = rows.findIndex((id) => id === curRow)
      const focusedRowIndex = Math.max(curRowIndex - 1, 0)
      activeColId = curCol
      activeRowId = rows[focusedRowIndex]
    }
    if (ArrowKeys.has(e.key) || EscapeKeys.has(e.key)) {
      editing = false
      e.preventDefault()
    }
  }

  export const flashRow = (rowId: string) => {
    rowComponents[rowId]?.flashRow()
  }
</script>

<table
  bind:this={tableElem}
  role="presentation"
  border={0}
  on:keydown={checkArrows}
  on:focusout={focusOut}
  class={classNames(className ?? "", "table", {
    transpose,
    striped,
    fullwidth,
    "layout-fixed": layoutFixed,
  })}
>
  {#if transpose}
    {#each columns as colId (colId)}
      <tr class="datatable-row">
        {#each rows as rowId}
          {@const cellData = cellDataProvider({ colId, rowId })}
          <CondWrapper
            wrap={!cellData.internal?.skipWrapWithTd}
            component={DataTableTd}
            {rowId}
            {colId}
          >
            <svelte:component
              this={cellRenderer}
              editing={colId === activeColId &&
                rowId === activeRowId &&
                editing}
              active={colId === activeColId && rowId === activeRowId}
              {rowId}
              {transpose}
              {readonly}
              columnId={colId}
              on:requestEdit={() => requestEdit(rowId, colId)}
              on:requestStopEdit={requestStopEdit}
              on:requestFocus={() => focusedCell(rowId, colId)}
              {...cellData}
            />
          </CondWrapper>
        {/each}
      </tr>
    {/each}
  {:else}
    {#each rows as rowId (rowId)}
      <TableRow
        on:action
        on:requestEdit={(e) => requestEdit(rowId, e.detail)}
        on:requestStopEdit={requestStopEdit}
        on:requestFocus={(e) => focusedCell(rowId, e.detail)}
        active={activeRowId === rowId}
        bind:this={rowComponents[rowId]}
        {activeColId}
        {transpose}
        editing={activeRowId === rowId && editing}
        {columns}
        {rowId}
        {readonly}
        {cellRenderer}
        {cellDataProvider}
      />
    {/each}
  {/if}
</table>

<style>
  :root {
    --table-width: auto;
  }
  .sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .table {
    border-spacing: 0;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .table.fullwidth {
    width: 100%;
  }
  .layout-fixed {
    table-layout: fixed;
  }
  .actions-col-header {
    width: 40px;
  }
  .transpose .actions-col-header {
    width: 170px;
  }
</style>
