<script lang="ts">
  import { apiClient } from "@/api"
  import type { PartialRecord } from "@/global"
  import Checkbox from "@/lib/Checkbox.svelte"
  import type { ProxyConfig } from "@shared/api/source.ts"
  import type { Source } from "@shared/schema/enum"
  import { EnumT } from "@shared/schema/index.ts"
  import { objectValues } from "@shared/util/index.ts"

  import { onMount } from "svelte"

  let proxySettingsBySource:
    | PartialRecord<EnumT.Source, ProxyConfig>
    | undefined = undefined

  $: sources = objectValues(EnumT.Source)

  const refreshProxySettings = async () => {
    const settingsMap = await apiClient.call("getSourceProxySettings", {})

    if (!proxySettingsBySource) {
      proxySettingsBySource = {}
    }

    for (const source of sources) {
      proxySettingsBySource[source] = settingsMap[source] ?? {}
    }
  }

  onMount(() => {
    void refreshProxySettings()
  })

  const onChange =
    <S extends Source, K extends keyof ProxyConfig>(source: S, k: K) =>
    (e: Event) => {
      const elem = e.target as HTMLInputElement
      proxySettingsBySource![source]![k] = elem.checked as ProxyConfig[K]
    }
</script>

<main>
  {#if proxySettingsBySource}
    <section>
      {#each sources as source}
        {source}
        {proxySettingsBySource[source]}
        {#if proxySettingsBySource[source]}
          <Checkbox
            checked={proxySettingsBySource[source]?.jsRender}
            on:change={onChange(source, "jsRender")}
            label="Render JS"
          />
          <Checkbox
            checked={proxySettingsBySource[source]?.antibot}
            on:change={onChange(source, "antibot")}
            label="Antibot"
          />
        {/if}
      {/each}
    </section>
  {/if}
</main>
