<script lang="ts">
  import ImagePreviewCarousel, {
    type ImageInfo,
  } from "../ImagePreviewCarousel.svelte"

  export let images: ImageInfo[] = []
  export let maxHeight: number | undefined = undefined
</script>

<ImagePreviewCarousel {images} {maxHeight} globalArrowKeys />
