import { safeParseFloat } from "."

export type LatLonObj = { lat: number; lon: number }
export type LatLonTuple = [number, number]

export class Location {
  lat: number
  lon: number

  constructor(input?: LatLonObj | LatLonTuple) {
    if (!input) {
      this.lat = 0
      this.lon = 0
    } else if (Array.isArray(input)) {
      this.lat = input[0]
      this.lon = input[1]
    } else {
      const { lat, lon } = input
      this.lat = lat
      this.lon = lon
    }
  }

  public nonZero = () => !!(this.lat && this.lon)
  public toTuple = (): LatLonTuple => [this.lat, this.lon]
  public toObj = () => ({ lat: this.lat, lon: this.lon })
}

const safeCreateLocationFromStringInner = (
  locString: string,
  separator: string | RegExp
) => {
  const separated = locString.trim().split(separator)
  if (separated.length !== 2) {
    return null
  }
  const lat = safeParseFloat(separated[0].trim())
  const lon = safeParseFloat(separated[1].trim())
  if (lat == null || lon == null) {
    return null
  }
  return new Location({ lat, lon })
}

export const safeCreateLocationFromString = (locString: string) => {
  return (
    safeCreateLocationFromStringInner(locString, ";") ??
    safeCreateLocationFromStringInner(locString, ",")
  )
}
