<script lang="ts">
  export let flexRight: boolean = false
  export let flexLeft: boolean = false
</script>

<div class="page">
  <div class="left" class:flex={flexLeft}>
    <slot name="left" />
  </div>
  <div class="body">
    <slot />
  </div>
  <div class="right" class:flex={flexRight}>
    <slot name="right" />
  </div>
</div>

<style>
  .page {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .body {
    flex: 1;
    overflow-x: auto;
    width: 100%;
  }
  .flex {
    display: flex;
  }
</style>
