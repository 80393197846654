import {
  faBed,
  faBorderAll,
  faCalendar,
  faCalendarDay,
  faCar,
  faCarBurst,
  faCertificate,
  faChair,
  faChargingStation,
  faCircleCheck,
  faDollarSign,
  faDoorOpen,
  faFileCircleCheck,
  faFingerprint,
  faGasPump,
  faHashtag,
  faImages,
  faIndustry,
  faLink,
  faLocationDot,
  faNetworkWired,
  faPalette,
  faPerson,
  faPhone,
  faPoundSign,
  faRoad,
  faRotateLeft,
  faStar,
  faToilet,
  faTruckFast,
  faTruckPickup,
  faUserGear,
  type IconDefinition,
} from "@fortawesome/free-solid-svg-icons"
import { EnumT } from "@shared/schema/index.ts"

export const fieldToIconMap: Record<EnumT.Field, IconDefinition> = {
  [EnumT.Field.beds]: faBed,
  [EnumT.Field.priceUsd]: faDollarSign,
  [EnumT.Field.baths]: faToilet,
  [EnumT.Field.title]: faHashtag,
  [EnumT.Field.url]: faLink,
  [EnumT.Field.images]: faImages,
  [EnumT.Field.location]: faLocationDot,
  [EnumT.Field.availableAt]: faCalendarDay,
  [EnumT.Field.dateListed]: faCalendarDay,
  [EnumT.Field.source]: faNetworkWired,
  [EnumT.Field.sqft]: faBorderAll,
  [EnumT.Field.priceGbp]: faPoundSign,
  [EnumT.Field.certifiedPreowned]: faCertificate,
  [EnumT.Field.sellerName]: faPerson,
  [EnumT.Field.sellerUrl]: faLink,
  [EnumT.Field.sellerPhone]: faPhone,
  [EnumT.Field.cityMpg]: faGasPump,
  [EnumT.Field.highwayMpg]: faGasPump,
  [EnumT.Field.cylinderCount]: faCar,
  [EnumT.Field.drivetrain]: faCar,
  [EnumT.Field.engine]: faCar,
  [EnumT.Field.noAccidents]: faCarBurst,
  [EnumT.Field.cleanTitle]: faFileCircleCheck,
  [EnumT.Field.openRecall]: faRotateLeft,
  [EnumT.Field.trim]: faCar,
  [EnumT.Field.bodyStyle]: faCar,
  [EnumT.Field.cabType]: faTruckPickup,
  [EnumT.Field.personalUse]: faPerson,
  [EnumT.Field.make]: faIndustry,
  [EnumT.Field.model]: faCar,
  [EnumT.Field.homeDelivery]: faTruckFast,
  [EnumT.Field.year]: faCalendar,
  [EnumT.Field.predictedPriceUsd]: faDollarSign,
  [EnumT.Field.doorCount]: faDoorOpen,
  [EnumT.Field.seatCount]: faChair,
  [EnumT.Field.exteriorColor]: faPalette,
  [EnumT.Field.interiorColor]: faPalette,
  [EnumT.Field.usageStatus]: faUserGear,
  [EnumT.Field.mileage]: faRoad,
  [EnumT.Field.fuelType]: faChargingStation,
  [EnumT.Field.financingEligible]: faDollarSign,
  [EnumT.Field.sellerType]: faPerson,
  [EnumT.Field.rating]: faStar,
  [EnumT.Field.oneOwner]: faPerson,
  [EnumT.Field.numReviews]: faPerson,
  [EnumT.Field.externalId]: faFingerprint,
  [EnumT.Field.isAvailable]: faCircleCheck,
}

export const ActionKeys = new Set(["Enter", "Space"])
export const EscapeKeys = new Set(["Escape"])

export enum ArrowKey {
  right = "ArrowRight",
  left = "ArrowLeft",
  up = "ArrowUp",
  down = "ArrowDown",
}

export const XArrowKeys = new Set<string>([ArrowKey.right, ArrowKey.left])
export const YArrowKeys = new Set<string>([ArrowKey.up, ArrowKey.down])
export const ArrowKeys = new Set<string>([...XArrowKeys, ...YArrowKeys])

export const TabKey = "Tab"

export const DEFAULT_COL_WIDTH = 200
