<script lang="ts">
  import { listRoute, tableRoute } from "@/routes"
  import { isMobile } from "@/util"
  import Button from "./Button.svelte"
  import RouterLink from "./RouterView/RouterLink.svelte"

  export let sheetId: string
  export let component: "button" | "link" = "link"

  $: route = isMobile() ? listRoute({ sheetId }) : tableRoute({ sheetId })
</script>

{#if component === "link"}
  <RouterLink to={route}>
    <slot />
  </RouterLink>
{:else if component === "button"}
  <Button nofocus to={route}><slot /></Button>
{/if}
