import { getSocket } from "@/client/socket"
import { ACTIONS_AXIS_ID } from "@/lib/DataTable/constants"
import type { ColAction, RowAction } from "@/lib/DataTable/types"
import type { GenericDataValue } from "@shared/types.ts"
import { coalesceEmptyArray } from "@shared/util"

export const buildCellDataProvider =
  ({
    sheetId,
    buildRowActionsForRow,
    buildColActionsForCol,
    hideSortControls,
  }: {
    sheetId: string
    buildRowActionsForRow: (rowId: string) => RowAction[]
    buildColActionsForCol: (colId: string) => ColAction[]
    hideSortControls?: boolean
  }) =>
  ({ rowId, colId }: { rowId: string; colId: string }) => ({
    sheetId,
    onChangeCB: async (value: GenericDataValue | undefined) =>
      (await getSocket()).emit("editCell", {
        rowId,
        colId,
        data: value,
        sheetId,
      }),
    rowActions:
      colId === ACTIONS_AXIS_ID
        ? coalesceEmptyArray(buildRowActionsForRow?.(rowId))
        : [],
    colActions:
      rowId === ACTIONS_AXIS_ID
        ? coalesceEmptyArray(buildColActionsForCol?.(colId))
        : [],
    hideSortControls: hideSortControls ?? false,
    internal: {
      skipWrapWithTd: rowId === ACTIONS_AXIS_ID,
    },
  })
