import { createModalManager } from "@/lib/modalManager"
import ShareModal from "./ShareModal.svelte"
import AuthCallbackModal from "./AuthCallbackModal.svelte"
import ImagePreviewModal from "./ImagePreviewModal.svelte"
import EditLocationModal from "./EditLocationModal.svelte"
import EditAccessModal from "./EditAccessModal.svelte"
import ChooseListingTypeModal from "./ChooseListingTypeModal.svelte"
import type { Location } from "@shared/util"
import type { ComponentProps } from "svelte"
import type { EnumT } from "@shared/schema"

export const globalModalManager = createModalManager()

export const globalShareModal = globalModalManager.makeModal({
  title: "Share",
  description: "share to other platforms",
  component: ShareModal,
})

export const globalAuthCallbackModal = globalModalManager.makeModal<
  boolean,
  ComponentProps<AuthCallbackModal>
>({
  title: "Link Account",
  description: "Choose whether to link the local account",
  component: AuthCallbackModal,
})

export const globalImagePreviewModal = globalModalManager.makeModal({
  title: "Images",
  description: "Preview images",
  showX: true,
  fullscreen: true,
  component: ImagePreviewModal,
})

export const globalEditLocationModal = globalModalManager.makeModal<
  Location,
  ComponentProps<EditLocationModal>
>({
  title: "Set Location",
  description: "set location",
  component: EditLocationModal,
})

export const globalEditAccessModal = globalModalManager.makeModal<
  EnumT.AccessType,
  ComponentProps<EditAccessModal>
>({
  title: "Share Settings",
  description: "Modify default access for users who visit your sheet",
  showX: true,
  fullscreen: false,
  component: EditAccessModal,
})

export const globalChooseListingTypeModal = globalModalManager.makeModal<
  EnumT.ListingType | null,
  ComponentProps<ChooseListingTypeModal>
>({
  title: "What are you searching for?",
  description: "Choose a search type",
  showX: true,
  fullscreen: false,
  component: ChooseListingTypeModal,
})
