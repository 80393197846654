import { isMobileApp } from "@/mobile"
import { Share } from "@capacitor/share"
import type { ShareableData } from "./types.ts"
import { isMobile } from "@/util"

export { Shareable } from "./data.ts"

const transformShareableDataForWeb = (shareData: ShareableData): ShareData => ({
  title: shareData.dialogTitle ?? shareData.title,
  text: shareData.text ?? shareData.title ?? shareData.dialogTitle,
  url: shareData.url,
})

export const doShare = async (shareData: ShareableData) => {
  if (isMobileApp()) {
    await Share.share(shareData)
  } else {
    await navigator.share(transformShareableDataForWeb(shareData))
  }
}

export const canShare = async (shareData: ShareableData) =>
  (isMobileApp() && (await Share.canShare())) ||
  (isMobile() &&
    "canShare" in navigator &&
    navigator.canShare(transformShareableDataForWeb(shareData)))
