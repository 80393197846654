<script lang="ts">
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import type { DataFormatOverrides } from "./types"
  import type { EnumT } from "@shared/schema"
  import Checkbox from "@/lib/Checkbox.svelte"

  export let autofocus: boolean = false
  export let value: GenericDataValue<EnumT.DataType.boolean> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}
</script>

<Checkbox bind:checked={value} {autofocus} />
