<script lang="ts">
  import { EnumT } from "@shared/schema"
  import type { FormatSpec, GenericDataValue } from "@shared/types"
  import type { DataFormatOverrides } from "./types"

  export let textOnly: boolean = false
  export let value: GenericDataValue<EnumT.DataType.text> | undefined
  export let formatSpec: FormatSpec
  export let dataFormatOverrides: DataFormatOverrides = {}

  $: displayedValue = ((): string => {
    if (value === undefined) {
      return ""
    }
    switch (formatSpec.type) {
      case EnumT.FormatSpecType.none:
        return value.toString()
      case EnumT.FormatSpecType.checkbox:
        return value ? "✅" : "❌"
      default:
        return `ERR: unsupported format type: ${formatSpec.type}`
    }
  })()
</script>

{#if textOnly}
  {displayedValue}
{:else}
  {displayedValue}
{/if}
